import { Table } from 'react-bootstrap'
import Layout from '../../Layouts'
import { faturaBilgileri } from '../../../data/fatura-bilgileri'
import { useTranslation } from 'react-i18next';

export default function Fatura() {
    const { t } = useTranslation();

    return (
        <Layout>
            <div className='bg-white'>
                <h3 className='text-center my-2 pt-2'>{t('FaturaBilgileri')}</h3>
                <hr className='m-0' />
                <Table>
                    <tbody>
                        {
                            Object.entries(faturaBilgileri).map(([key, value]) => {
                                return (
                                    <tr key={key}>
                                        <td width={100}>{t(key)}</td>
                                        <td width={5}>:</td>
                                        <td className='text-muted'>{value}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </Layout>
    )
}
