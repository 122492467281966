import { useTranslation } from 'react-i18next';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  Scrolling,
  Selection,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import ScrollView from 'devextreme-react/scroll-view';
import '../../../assets/css/devexpress/dx.common.css';
import '../../../assets/css/devexpress/dx.material.blue.light.css';
import { useEffect, useState } from 'react';
import { HorizontalLine } from 'devextreme-react/chart';
import WaitingSpinner from '../../../components/WaitingSpinner';
import useCargoApi from '../../../services/api/useCargoApi';
import KargoTakipDetail from './KargoTakipDetail';
import Layout from '../../Layouts';
import TabNavigation from '../../../components/TabNavigation';
import useAuth from '../../../features/hooks/useAuth';

function KargoTakip() {
  const { t } = useTranslation();
  const { GetCargoTakipList } = useCargoApi();
  const [data, setData] = useState('');
  const [subAccount, setSubAccount] = useState(0);
  const { user } = useAuth();

  useEffect(() => {
    async function getData() {
      const result = await GetCargoTakipList(subAccount);
      setData(result);
    }
    getData();
    return () => {
      setData('');
    };
  }, [subAccount]);

  function selectionChanged(e) {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
  }

  if (data) {
    return (
      <Layout>
        {user?.subAccount == 1 && (
          <TabNavigation
            activeTab={subAccount}
            setActiveTab={(value) => setSubAccount(value)}
          />
        )}
        <ScrollView direction="horizontal">
          <DataGrid
            id="kargotakip"
            dataSource={data}
            allowColumnReordering={false}
            scrollingEnabled={false}
            // defaultSelectedRowKeys={0}
            columnAutoWidth={true}
            focusedRowEnabled={true}
            keyExpr="kayitNo"
            onSelectionChanged={(e) => selectionChanged(e)}
            // onContentReady={(e) => contentReady(e)}
          >
            <GroupPanel visible={false} />
            <Grouping autoExpandAll={false} />
            <FilterRow visible={false} />
            <Selection mode={'none'} />
            <HeaderFilter visible={false} />
            <Scrolling preloadEnabled={true} mode={HorizontalLine} />
            <Column
              dataField={'kayitNo'}
              caption={t('KayitNo')}
              allowSorting={false}
              allowFiltering={true}
              allowGrouping={false}
              allowReordering={false}
              width={0}
            />

            <Column
              caption={t('KapSayisi')}
              dataField={'kapSayisi'}
              width={75}
            />
            <Column
              caption={t('GonderenAdi')}
              dataField={'gonderenAdi'}
              width={120}
            />
            <Column
              caption={t('MusteriKodu')}
              dataField={'musteriKodu'}
              width={120}
            />
            <Column caption={t('StokAdi')} dataField={'stokAdi'} width={120} />

            <Column
              caption={t('Tarih')}
              dataField={'tarih'}
              dataType={'date'}
              format={'dd.MM.yyyy'}
              width={100}
              sortOrder={'asc'}
            />
            <Column dataField={'fisNo'} caption={t('EvrakNo')} width={100} />
            <Column dataField={'aracNo'} dataType={'string'} width={125} />

            <Column
              caption={t('M3')}
              dataField={'m3'}
              format="#,##0.00"
              width={120}
            />
            <Summary>
              <TotalItem column="tarih" summaryType="count" />
              <TotalItem column="kapSayisi" summaryType="sum" />
              <TotalItem
                column="m3"
                summaryType="sum"
                valueFormat="#,###,##0.00"
              />
            </Summary>
            <Pager
              allowedPageSizes={[25, 50, 100, 1000]}
              showPageSizeSelector={true}
            />
            <Paging defaultPageSize={25} />
            <MasterDetail
              enabled={true}
              render={(e) => <KargoTakipDetail data={e} />}
            />
          </DataGrid>
        </ScrollView>
      </Layout>
    );
  } else {
    return <WaitingSpinner data={data} />;
  }
}

export default KargoTakip;
