import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import useMonthNames from '@/helpers/useMonthNames';
import Layout from '@/content/Layouts';
import useCargoApi from '@/services/api/useCargoApi';
import useDate from '@/features/hooks/useDate';
import useAuth from '@/features/hooks/useAuth';
import useMonth from '@/features/hooks/useMonth';
import { useNavigate } from 'react-router-dom';
import TabNavigation from '@/components/TabNavigation';
import formatCurrency from '@/utils/format-currency';
import CountUp from 'react-countup';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: 'Tarih - M3'
    }
  }
};

function CargoDashboard() {
  const { t } = useTranslation();
  const { GetCargoGonderiList, GetAnaCariBakiyeList, GetCargoBakiye } =
    useCargoApi();
  const [bakiyeList, setBakiyeList] = useState([]);
  const [kargoBakiye, setKargoBakiye] = useState(0);
  const [subAccount, setSubAccount] = useState(0);
  const [gonderiData, setGonderiData] = useState('');
  const { ilkTarih, sonTarih } = useDate();
  const { monthNames } = useMonthNames();
  const { user } = useAuth();
  const labels = useMonth();
  let navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      const result = await GetCargoGonderiList(ilkTarih, sonTarih, subAccount);
      setGonderiData(result);
      const bakiyeList = await GetAnaCariBakiyeList(subAccount);
      setBakiyeList(bakiyeList);
      const kargoBakiyeResponse = await GetCargoBakiye(subAccount);
      setKargoBakiye(
        kargoBakiyeResponse?.[0]?.bakiye ? kargoBakiyeResponse[0].bakiye : 0
      );
    }
    if (ilkTarih && sonTarih && user) {
      getData();
    }
    return () => {
      setGonderiData('');
    };
  }, [ilkTarih, sonTarih, user, subAccount]);

  let toplamKap = 0;
  let toplamKg = 0;
  let toplamM3 = 0;
  const kapsayisi = [];
  const m3 = [];

  if (gonderiData) {
    gonderiData.forEach((el) => {
      const elDate = new Date(el.tarih);
      toplamKap += parseInt(el.kapSayisi);
      toplamKg += parseFloat(el.kg);
      toplamM3 += parseFloat(el.m3);
      let currentLabel = '';
      if (elDate.getFullYear() === new Date().getFullYear()) {
        currentLabel = monthNames[elDate.getMonth()];
      } else {
        currentLabel = monthNames[elDate.getMonth()] + elDate.getFullYear();
      }
      labels.forEach((label) => {
        if (label == currentLabel) {
          kapsayisi[labels.indexOf(label)] =
            (kapsayisi[labels.indexOf(label)] || 0) + parseInt(el.kapSayisi);
          m3[labels.indexOf(label)] =
            (m3[labels.indexOf(label)] || 0) + parseInt(el.m3);
        }
      });
    });
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Kapsayısı',
        data: kapsayisi,
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      },
      {
        label: 'M3',
        data: m3,
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  };

  return (
    <Layout>
      {user?.subAccount == 1 && (
        <TabNavigation
          activeTab={subAccount}
          setActiveTab={(value) => setSubAccount(value)}
        />
      )}
      <div className="container mx-auto my-8 px-4">
        <div className="grid gap-6 md:grid-cols-3">
          <div className="bg-white rounded-lg p-4 gl-card-decorative">
            <h4 className="border-b border-gray-200 pb-2 mb-2 text-lg font-semibold text-gray-700">
              {t('HavaleBakiye')}
            </h4>
            {bakiyeList?.map((el, i) => (
              <div key={i} className="border-b border-gray-200 py-2">
                <div className="pb-2">
                  <h6 className="text-gray-600">{t(el.hesapAdi)}</h6>
                </div>
                <div className="d-flex justify-content-around items-center">
                  <div className="text-right">
                    <h6 className="text-xl font-bold text-color-decorative">
                      {formatCurrency(el?.bakiye)}
                    </h6>
                  </div>
                  <div className="text-right">
                    <h6 className="text-lg text-color-decorative">
                      {el.doviz}
                    </h6>
                  </div>
                  <div className="text-right">
                    <button
                      className="bg-color-decorative text-white px-3 py-1 rounded"
                      onClick={() => navigate(`/kargo/HavaleHesap/${el.kodu}`)}>
                      {t('Detay')}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="bg-white rounded-lg p-4 gl-card-decorative">
            <h4 className="border-b pb-2 mb-2 text-lg font-semibold text-gray-700">
              {t('DepoBakiye')}
            </h4>
            <div className="d-flex justify-content-around items-center">
              <div className="text-right">
                <h4 className="text-xl font-bold text-color-decorative">
                  {formatCurrency(kargoBakiye)}
                </h4>
              </div>
              <div className="text-right">
                <h6 className="text-gray-600">USD</h6>
              </div>
              <div className="text-right">
                <button
                  className="bg-color-decorative text-white px-3 py-1 rounded"
                  onClick={() => navigate(`/kargo/KargoHesap`)}>
                  {t('Detay')}
                </button>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg p-4 gl-card-decorative flex items-center justify-center">
            <div className="flex flex-row space-x-6">
              <div className="text-center">
                <p className="text-gray-600">{t('PaketSayisi')}</p>
                <CountUp
                  start={0}
                  end={toplamKap}
                  duration={2}
                  className="text-3xl font-bold text-color-decorative"
                />
              </div>
              <div className="text-center">
                <p className="text-gray-600">{t('Kg')}</p>
                <CountUp
                  start={0}
                  end={toplamKg}
                  duration={2}
                  className="text-3xl font-bold text-color-decorative"
                />
              </div>
              <div className="text-center">
                <p className="text-gray-600">{t('M3')}</p>
                <CountUp
                  start={0}
                  end={toplamM3.toFixed(2)}
                  duration={2}
                  decimals={2}
                  className="text-3xl font-bold text-color-decorative"
                />
              </div>
            </div>
          </div>

          <div className="col-span-full gl-card-decorative rounded-lg">
            <div className="bg-color-decorative text-white text-center py-2 font-bold">
              {t('M3Kg')}
            </div>
            <div className="p-4">
              {gonderiData && <Bar options={options} data={chartData} />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CargoDashboard;
