import Layout from '../../Layouts';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useDate from '../../../features/hooks/useDate';
import ScrollView from 'devextreme-react/scroll-view';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import '../../../assets/css/devexpress/dx.common.css';
import '../../../assets/css/devexpress/dx.material.blue.light.css';
import useHavaleApi from '../../../services/api/useHavaleApi';
import WaitingSpinner from '@/components/WaitingSpinner';

function HavaleListesi() {
  const { t } = useTranslation();
  const { GetHavaleList } = useHavaleApi();

  const [data, setData] = useState('');
  const { ilkTarih, sonTarih } = useDate();

  useEffect(() => {
    async function getData() {
      const result = await GetHavaleList(ilkTarih, sonTarih);
      setData(result);
    }

    getData();

    return () => {
      setData('');
    };
  }, [ilkTarih, sonTarih]);

  const renderGridCell = ({ data }) => {
    let btnClass = '';
    switch (data.durum) {
      case 0:
        btnClass = 'bg-secondary';
        break;
      case 1:
        btnClass = 'bg-warning';
        break;
      case 2:
        btnClass = 'bg-primary';
        break;
      case 3:
        btnClass = 'bg-success';
        break;
      case 5:
        btnClass = 'bg-danger';
        break;
      default:
        btnClass = 'bg-secondary';
        break;
    }
    return (
      <button
        className={`btn rounded-pill text-white btn-sm py-1 w-75 gl-btn-max-width ${btnClass}`}>
        {t(data.durumAdi)}
      </button>
    );
  };

  if (data) {
    return (
      <Layout>
        <ScrollView direction="horizontal">
          <DataGrid
            dataSource={data}
            allowColumnReordering={false}
            scrollingEnabled={false}
            // defaultSelectedRowKeys={0}
            columnAutoWidth={true}
            focusedRowEnabled={true}
            keyExpr="kayitNo">
            <GroupPanel visible={false} />
            <Grouping autoExpandAll={true} />
            <FilterRow visible={true} />
            {/* <Selection mode={'multiple'} /> */}
            <HeaderFilter visible={true} />
            <Column
              caption={t('Tarih')}
              dataField={'tarih'}
              dataType={'date'}
              format={'dd.MM.yyyy'}
              width={115}
            />

            <Column
              caption={t('Tutar')}
              dataField={'araToplam'}
              format="#,##0.00"
              width={150}
            />
            <Column
              caption={t('Durum')}
              dataField={'durumAdi'}
              width={140}
              cellRender={renderGridCell}
            />
            <Column
              caption={t('Aciklama')}
              dataField={'aciklama1'}
              dataType={'string'}
              width={150}
            />
            <Column
              caption={t('Aciklama')}
              dataField={'aciklama3'}
              width={150}
            />

            <Column
              caption={t('KayitNo')}
              dataField={'kayitNo'}
              allowSorting={false}
              allowFiltering={true}
              allowGrouping={false}
              allowReordering={false}
              width={100}
              visible={false}
            />
            <Summary>
              <TotalItem column="tarih" summaryType="count" />
              <TotalItem
                column="araToplam"
                summaryType="sum"
                valueFormat="#,###,##0.00"
              />
            </Summary>

            <Pager
              allowedPageSizes={[25, 50, 100, 1000]}
              showPageSizeSelector={true}
            />
            <Paging defaultPageSize={50} />
          </DataGrid>
        </ScrollView>
      </Layout>
    );
  } else {
    return <WaitingSpinner data={data} />;
  }
}
export default HavaleListesi;
