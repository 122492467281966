import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactCodeInput from 'react-code-input';
import { renderIcon } from '@/utils/iconRenderer';
import { t } from 'i18next';
import useAppApi from '@/services/api/setUserApi';
import SetUserApi from '@/services/api/setUserApi';
import useAuth from '@/features/hooks/useAuth';
import useMessage from '@/features/hooks/useMessage';

export default function VerifyCode() {
  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Uyarı mesajı için state
  const { state } = useLocation();
  const userName = state?.userName || '';
  const phoneLoginFL = state?.phoneLoginFL || false;
  const { VerifyCode } = useAppApi();
  const navigate = useNavigate();
  const { LoginPhone } = SetUserApi();
  const { loginByPhone } = useAuth();
  const { messageBox } = useMessage();

  const handleVerify = async () => {
    if (!otp || otp.length < 6) {
      setErrorMessage(t('Lutfen6HaneliDogrulamaKodunuGiriniz')); // Hata mesajı güncelleniyor
      return;
    }

    setErrorMessage(''); // Başarılı giriş denemesinde hata mesajını temizle

    if (phoneLoginFL) {
      const response = await LoginPhone({ Phone: userName, Code: otp });

      if (response) {
        try {
          await loginByPhone(response.data.token);
          navigate('/');
        } catch (error) {
          console.error('Apple login failed', error);
        }
      }
    } else {
      const response = await VerifyCode({ UserName: userName, Code: otp });
      if (response.success) {
        navigate('/auth/reset-password', { state: { userName, otp } });
      } else {
        messageBox(response?.message, 'error', 500);
      }
    }
    setErrorMessage('');
  };

  const handleChange = (value) => {
    setOtp(value);
    if (errorMessage) setErrorMessage(''); // Kullanıcı yazarken hata mesajını temizle
  };

  const inputStyle = {
    fontFamily: 'monospace',
    MozAppearance: 'textfield',
    borderRadius: '5px',
    border: '1px solid #ccc',
    margin: '2px',
    paddingLeft: '8px',
    width: '40px',
    height: '40px',
    fontSize: '18px',
    textAlign: 'center',
    outline: 'none',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)'
  };

  const focusStyle = {
    borderColor: '#6366F1',
    boxShadow: '0 0 5px rgba(99, 102, 241, 0.5)'
  };

  return (
    <div className="container px-4 px-lg-0 login-container">
      <div className="row d-grid d-flex bg-sm-image">
        <div className="m-auto rounded-lg form-login g-0">
          <div className="card g-0" border="gray">
            <div className="card-header card-header-title text-color-success">
              {renderIcon('verified-user', 32, 'text-color-black')}
              <div>{t('ConfirmPassword')}</div>
            </div>

            <div className="card-body">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleVerify();
                }}
                className="space-y-6">
                <div className="text-center mb-4">
                  {t('LutfenSizeGonderilenDogrulamaKodunuGirin')}
                </div>

                {/* Uyarı Mesajı */}
                {errorMessage && (
                  <div className="alert alert-danger text-center mb-4">
                    {errorMessage}
                  </div>
                )}

                {/* Doğrulama Kodu Girişi */}
                <div className="relative form-outline flex justify-center items-center content-center">
                  <ReactCodeInput
                    type="number"
                    className="mx-3 my-3"
                    fields={6}
                    value={otp}
                    onChange={handleChange}
                    inputStyle={inputStyle}
                    focusStyle={focusStyle}
                  />
                </div>

                <button className="btn-login mt-5 mx-5 my-2" type="submit">
                  {renderIcon('domain-verification', 32, 'text-color-black')}
                  <p className="mx-1 my-1">{t('Confirm')}</p>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
