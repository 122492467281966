import { useState, useEffect } from "react";
import "./Register.css"
import { Link, useNavigate } from "react-router-dom";
import useAuth from '../../../features/hooks/useAuth';
import { useTranslation } from "react-i18next";

function Register() {

    const [FullName, setFullName] = useState("");
    const [Email, setEmail] = useState("");
    const [UserName, setUserName] = useState("");
    const [MobilePhones, setMobilePhones] = useState("");
    const [Password, setPassword] = useState("");
    let navigate = useNavigate();
    const userInfo = { FullName, Email, UserName, MobilePhones, Password }
    const { t } = useTranslation();

    const { register, isAuthenticated } = useAuth();

    function submitHandler(e) {
        e.preventDefault();
        register(userInfo);
    }

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated]);

    return (
        <div className="container px-4 px-lg-0 mb-4">
            <div className="text-center mt-4">
                <img src="/images/icons/logo2.png" className="img-fluid" width={342} height={162}></img>
            </div>
            <div className="row justify-content-center d-grid d-flex bg-sm-image">
                <div className="mt-4 col-lg-6">
                    <div className="card">
                        <div className="card-header">
                            <h3>{t('KayitOl')}</h3>
                        </div>
                        <div className="card-body pt-0 pb-2 ps-3 pe-3">
                            <form onSubmit={(e) => submitHandler(e)}>
                                <div className="form-group">
                                    <label className="mt-2">{t('AdiSoyadi')}</label>
                                    <input type="text" className="form-control" placeholder="Full Name" value={FullName} onChange={(e) => { setFullName(e.target.value) }} required/>
                                </div>
                                <div className="form-group">
                                    <label className="mt-2">{t('Email')}</label>
                                    <input type="email" className="form-control" placeholder="Enter Email" value={Email} onChange={(e) => { setEmail(e.target.value) }} required/>
                                </div>
                                <div className="form-group">
                                    <label className="mt-2">{t('KullaniciAdi')}</label>
                                    <input type="text" className="form-control" placeholder="User Name" value={UserName} onChange={(e) => { setUserName(e.target.value) }} required/>
                                </div>
                                <div className="form-group">
                                    <label className="mt-2">{t('Gsm')}</label>
                                    <input type="number" className="form-control" placeholder="Mobile Phone" value={MobilePhones} onChange={(e) => { setMobilePhones(e.target.value) }} required/>
                                </div>
                                <div className="form-group">
                                    <label className="mt-2">{t('Sifre')}</label>
                                    <input type="password" className="form-control" placeholder="Enter password" value={Password} onChange={(e) => { setPassword(e.target.value) }} required/>
                                </div>
                                <button type="submit" className="btn-submit btn btn-primary btn-block bg-color-success">{t('UyeOl')}</button>
                                <div className="signin">
                                    <p className="forgot-password text-right fs-7">
                                        {t('ZatenKayitli')} <Link to="/auth/login">{t('GirisYap')}</Link>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
