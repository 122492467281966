import { useTranslation } from 'react-i18next';

const TabNavigation = ({ activeTab, setActiveTab }) => {
  const { t } = useTranslation();

  const tabs = [
    { id: 'home', label: t('Bakiye'), value: 0 },
    { id: 'profile', label: t('AltBakiye'), value: 1 }
  ];

  return (
    <ul className="nav nav-tabs nav-pills flex-row text-center bg-light border-0 rounded-nav m-0 mb-3">
      {tabs.map((tab) => (
        <li key={tab.id} className="nav-item flex-fill">
          <a
            id={`${tab.id}-tab`}
            data-toggle="tab"
            role="tab"
            aria-controls={tab.id}
            aria-selected={activeTab === tab.value ? 'true' : 'false'}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab(tab.value);
            }}
            className={`nav-link border border-primary text-uppercase font-weight-bold cursor-pointer ${
              activeTab === tab.value ? 'active' : ''
            }`}>
            {tab.label}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default TabNavigation;
