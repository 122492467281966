import { useTranslation } from 'react-i18next';
import { DataGrid } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  Selection,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import '../../../assets/css/devexpress/dx.common.css';
import '../../../assets/css/devexpress/dx.material.blue.light.css';
import { useEffect, useState } from 'react';
import useDate from '../../../features/hooks/useDate';
import useCargoApi from '../../../services/api/useCargoApi';
import KargoHesapDetail from './KargoHesapDetail';
import Layout from '../../Layouts';
import { useLoading } from '@/context/LoadingContext';
function KargoHesap() {
  const { t } = useTranslation();
  const { setLoading } = useLoading();

  const { GetCargoHesapList } = useCargoApi();

  const [data, setData] = useState('');

  const { ilkTarih, sonTarih } = useDate();

  useEffect(() => {
    setLoading(true);
    async function getData() {
      const result = await GetCargoHesapList(ilkTarih, sonTarih);
      setData(result);
    }
    if (!data) {
      getData();
    }
    setLoading(false);
    return () => {
      setData('');
    };
  }, []);

  function selectionChanged(e) {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
  }

  return (
    <Layout>
      <ScrollView direction="horizontal">
        <DataGrid
          dataSource={data}
          allowColumnReordering={false}
          scrollingEnabled={false}
          // defaultSelectedRowKeys={0}
          columnAutoWidth={true}
          focusedRowEnabled={true}
          keyExpr="kayitNo"
          onSelectionChanged={(e) => selectionChanged(e)}
          // onContentReady={(e) => contentReady(e)}
        >
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={true} />
          <FilterRow visible={true} />
          <Selection mode={'none'} />
          <HeaderFilter visible={true} />
          <Column
            caption={t('Tarih')}
            dataField={'tarih'}
            dataType={'date'}
            format={'dd.MM.yyyy'}
            width={110}
          />
          <Column
            caption={t('AliciKodu')}
            dataField={'aliciKodu'}
            dataType={'string'}
            width={110}
          />
          <Column
            caption={t('Borc')}
            dataField={'borc'}
            format="#,###,##0.00"
            width={150}
          />
          <Column
            caption={t('Alacak')}
            dataField={'alacak'}
            format="#,###,##0.00"
            width={150}
          />
          <Column
            caption={t('Bakiye')}
            dataField={'bakiye'}
            format="#,###,##0.00"
            width={150}
          />
          <Column
            caption={t('AracNo')}
            dataField={'aracNo'}
            dataType={'string'}
            width={125}
          />

          <Column
            dataField={'kayitNo'}
            caption={t('KayitNo')}
            allowSorting={false}
            allowFiltering={true}
            allowGrouping={false}
            allowReordering={false}
            width={0}
            visible={false}
          />
          <Summary>
            <TotalItem column="tarih" summaryType="count" />
            <TotalItem
              column="borc"
              summaryType="sum"
              valueFormat="#,###,##0.00"
            />
            <TotalItem
              column="alacak"
              summaryType="sum"
              valueFormat="#,###,##0.00"
            />
            <TotalItem
              column="bakiye"
              summaryType="sum"
              valueFormat="#,###,##0.00"
            />
          </Summary>

          <Pager
            allowedPageSizes={[25, 50, 100, 1000]}
            showPageSizeSelector={true}
          />
          <Paging defaultPageSize={25} />
          <MasterDetail
            enabled={true}
            render={(e) => <KargoHesapDetail data={e} />}
          />
        </DataGrid>
      </ScrollView>
    </Layout>
  );
}

export default KargoHesap;
