import { useTranslation } from 'react-i18next';

export default function useMonthNames() {

    const { t } = useTranslation();

    const monthNames = [t("Ocak"), t("Subat"), t("Mart"), t("Nisan"), t("Mayis"), t("Haziran"), t("Temmuz"), t("Agustos"), t("Eylul"), t("Ekim"), t("Kasim"), t("Aralik")];

    return { monthNames }
}
