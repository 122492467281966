import { useTranslation } from 'react-i18next';
import Layout from '../../Layouts';

function About() {
  const { t } = useTranslation();

  return (
    <Layout>
      <h3>{t('Hakkimizda')}</h3>
      <div>
        <p>
          <strong>ALTUNTUR COMPANY</strong>
        </p>
        <p>
          Şirketimiz, Türkiye - Irak merkezli hizmet vermek amacıyla 1997
          yılında kurulmuş olup, sektörün en hızlı büyüyen şirketlerinden
          biridir. 24 yılı aşkın deneyimimizle, güven ve üstün hizmet
          anlayışımızla Irak’ın çeşitli bölgelerine hizmet sunmaktayız:
        </p>
        <ul>
          <li>Bağdat</li>
          <li>Basra</li>
          <li>Erbil</li>
          <li>Süleymaniye</li>
          <li>Kerkük</li>
          <li>Duhok</li>
          <li>Zaho</li>
          <li>Kerbela</li>
          <li>Hille</li>
          <li>Necef</li>
          <li>Samara</li>
          <li>Tikrit</li>
          <li>Akre</li>
          <li>Divaniye</li>
          <li>Diyana</li>
          <li>ve diğer bölgeler...</li>
        </ul>
        <p>
          <strong>ALTUNTUR</strong> Avrupa, Çin ve Türkiye’den Irak’a karayolu,
          havayolu ve denizyolu taşımacılığı hizmetleri sunmaktadır. Şirketimiz,
          kıtaları birbirine bağlayan geniş bir transit taşımacılık ağı
          oluşturarak dünya genelinde ihracat hizmeti vermektedir.
        </p>
      </div>
    </Layout>
  );
}

export default About;
