import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './CariHesapLimit.css';
import useGeneralApi from '@/services/api/useGeneralApi';
import useMessage from '@/features/hooks/useMessage';
import Layout from '@/content/Layouts';
import { renderIcon } from '@/utils/iconRenderer';

export default function CariHesapLimit() {
  const { t } = useTranslation();
  const currentDate = new Date().toISOString().slice(0, 10);
  const [vade, setVade] = useState(currentDate);
  const [total, setTotal] = useState(0);
  const [currency, setCurrency] = useState(1);
  const [code, setCode] = useState('');
  const [name, setName] = useState('');

  const { GetCustomerByInfoFromMikro, SetCustomerCreditLimit } =
    useGeneralApi();
  const { messageBox } = useMessage();

  const codeInputRef = useRef(null);

  useEffect(() => {
    // Sayfa yüklendiğinde code input'una odaklan
    if (codeInputRef.current) {
      codeInputRef.current.focus();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (total > 0) {
      const result = await SetCustomerCreditLimit({
        code,
        total,
        expiry: vade,
        currency
      });
      result?.data[0].sonuc === 1
        ? messageBox('İşlem tamamlandı', 'success')
        : messageBox('İşlem başarısız', 'error');
    } else {
      messageBox('Tutar 0 dan büyük olmalı', 'error');
    }
  };

  const searchCustomer = async (e) => {
    e.preventDefault();
    if (!code) {
      messageBox('Kod yazınız..', 'error');
      return;
    }

    const customer = await GetCustomerByInfoFromMikro(code);
    if (customer) {
      setName(customer.unvani);
      setCode(customer.kodu);
      setVade(currentDate);
      setTotal(0);
      setCurrency(customer.dovizCinsi);
    } else {
      messageBox('Hesap bulunamadı', 'error');
    }
  };

  return (
    <Layout>
      <div className="container mx-1 my-1">
        <div className="container mx-auto my-4">
          <header className="bg-color-decorative rounded p-3  text-color-white text-lg font-semibold">
            <p className="my-0">{t('Cari Hesap Limit')}</p>
          </header>
          <div className="form-outline my-2">
            <input
              type="text"
              id="code"
              value={code}
              className="form-control"
              onChange={(e) => setCode(e.target.value)}
              required
              placeholder={t('Code')}
              ref={codeInputRef}
            />
          </div>
          <button
            className="bg-secondary text-white py-2 px-8 rounded hover:bg-primary-dark mx-auto"
            type="button"
            onClick={searchCustomer}>
            {renderIcon('search', 24, 'mx-2')}
            {t('Search')}
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form-outline">
            <input
              type="text"
              name="name"
              id="name"
              value={name}
              className="form-control"
              disabled
            />
            <label htmlFor="name" className="mb-1 text-sm">
              {t('Name')}
            </label>
          </div>

          <div className="form-outline">
            <input
              type="date"
              value={vade}
              onChange={(e) => setVade(e.target.value)}
              className="form-control"
              required
            />
            <label className="mb-1 text-sm">{t('Vade')}</label>
          </div>

          <div className="form-outline">
            <input
              type="number"
              name="total"
              id="total"
              value={total}
              className="form-control"
              onChange={(e) => setTotal(e.target.value)}
              required
            />
            <label htmlFor="total" className="mb-1 text-sm">
              {t('Total')}
            </label>
          </div>
          <div className="form-outline">
            <select
              onChange={(e) => setCurrency(e.target.value)}
              name="currency"
              value={currency}
              className="form-control"
              required>
              <option value={0}>TL</option>
              <option value={1}>USD</option>
              <option value={2}>EURO</option>
            </select>
            <label className="mb-1 text-sm">{t('Doviz')}</label>
          </div>

          <button
            type="submit"
            className="bg-secondary w-full bg-primary text-white py-2 rounded hover:bg-primary-dark">
            {renderIcon('save', 24, 'mx-2')}
            {t('Kaydet')}
          </button>
        </form>
      </div>
    </Layout>
  );
}
