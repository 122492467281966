import { MdOutlineProductionQuantityLimits } from 'react-icons/md';
import { ImGift } from 'react-icons/im';
import { FaCodeBranch } from 'react-icons/fa';
import { RiMenuFill } from 'react-icons/ri';
import { RxReader } from 'react-icons/rx';
import {
  IoCarSportOutline,
  IoAirplaneOutline,
  IoBriefcaseOutline,
  IoStorefrontOutline,
  IoLogoUsd,
  IoLogoBitcoin,
  IoLogoTumblr,
  IoGiftOutline,
  IoReaderOutline,
  IoInformationOutline
} from 'react-icons/io5';
import { GiEarthAmerica } from 'react-icons/gi';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { SlCalender } from 'react-icons/sl';
import { IoIosNotifications } from 'react-icons/io';
import { BsMenuButtonWideFill } from 'react-icons/bs';
import { IoMdExit } from 'react-icons/io';
import { MdOutlineDarkMode } from 'react-icons/md';
import { CiLight } from 'react-icons/ci';
import { MdDarkMode } from 'react-icons/md';
import { FaRegUser } from 'react-icons/fa';
import { FaShippingFast } from 'react-icons/fa';
import { CgList } from 'react-icons/cg';
import { LuLayoutDashboard } from 'react-icons/lu';
import { FaMoneyBillTransfer } from 'react-icons/fa6';
import { FaPowerOff } from 'react-icons/fa6';
import { PiPasswordDuotone } from 'react-icons/pi';
import { MdOutlineSendToMobile } from 'react-icons/md';
import { MdOutlineVerifiedUser } from 'react-icons/md';
import { MdOutlineDomainVerification } from 'react-icons/md';
import { GrPowerReset } from 'react-icons/gr';
import { IoCreateOutline } from 'react-icons/io5';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import { RiLockPasswordLine } from 'react-icons/ri';
import { GrSearch } from 'react-icons/gr';
import { IoMdSave } from 'react-icons/io';
import { IoChevronBackCircleOutline } from 'react-icons/io5';

export const renderIcon = (icon, size = 24, className = '') => {
  switch (icon) {
    case 'car-sport-outline':
      return <IoCarSportOutline size={size} className={className} />;
    case 'airplane-outline':
      return <IoAirplaneOutline size={size} className={className} />;
    case 'briefcase-outline':
      return <IoBriefcaseOutline size={size} className={className} />;
    case 'storefront-outline':
      return <IoStorefrontOutline size={size} className={className} />;
    case 'logo-usd':
      return <IoLogoUsd size={size} className={className} />;
    case 'logo-bitcoin':
      return <IoLogoBitcoin size={size} className={className} />;
    case 'logo-tumblr':
      return <IoLogoTumblr size={size} className={className} />;
    case 'gift-outline':
      return <IoGiftOutline size={size} className={className} />;
    case 'reader-outline':
      return <IoReaderOutline size={size} className={className} />;
    case 'information-outline':
      return <IoInformationOutline size={size} className={className} />;
    case 'earth-outline':
      return <GiEarthAmerica size={size} className={className} />;
    case 'production-quantity-limits':
      return (
        <MdOutlineProductionQuantityLimits size={size} className={className} />
      );
    case 'gift':
      return <ImGift size={size} className={className} />;
    case 'code-branch':
      return <FaCodeBranch size={size} className={className} />;
    case 'menu-outline':
      return <RiMenuFill size={size} className={className} />;
    case 'reader':
      return <RxReader size={size} className={className} />;
    case 'add-circle-outline':
      return <IoIosAddCircleOutline size={size} />;
    case 'calender':
      return <SlCalender size={size} className={className} />;
    case 'notifications':
      return <IoIosNotifications size={size} className={className} />;
    case 'menu-button-wide-fill':
      return <BsMenuButtonWideFill size={size} className={className} />;
    case 'exit':
      return <IoMdExit size={size} className={className} />;
    case 'dark-mode':
      return <MdOutlineDarkMode size={size} className={className} />;
    case 'light-mode':
      return <CiLight size={size} className={className} />;
    case 'high-contrast-mode':
      return <MdDarkMode size={size} className={className} />;
    case 'user':
      return <FaRegUser size={size} className={className} />;
    case 'shipping-fast':
      return <FaShippingFast size={size} className={className} />;
    case 'list':
      return <CgList size={size} className={className} />;
    case 'layout-dashboard':
      return <LuLayoutDashboard size={size} className={className} />;
    case 'money-bill-transfer':
      return <FaMoneyBillTransfer size={size} className={className} />;
    case 'power-off':
      return <FaPowerOff size={size} className={className} />;
    case 'password':
      return <PiPasswordDuotone size={size} className={className} />;
    case 'send-to-mobile':
      return <MdOutlineSendToMobile size={size} className={className} />;
    case 'verified-user':
      return <MdOutlineVerifiedUser size={size} className={className} />;
    case 'domain-verification':
      return <MdOutlineDomainVerification size={size} className={className} />;
    case 'power-reset':
      return <GrPowerReset size={size} className={className} />;
    case 'create-outline':
      return <IoCreateOutline size={size} className={className} />;
    case 'help-circle-outline':
      return <IoMdHelpCircleOutline size={size} className={className} />;
    case 'lock-password-line':
      return <RiLockPasswordLine size={size} className={className} />;
    case 'search':
      return <GrSearch size={size} className={className} />;
    case 'save':
      return <IoMdSave size={size} className={className} />;
    case 'back':
      return <IoChevronBackCircleOutline size={size} className={className} />;
    default:
      return <RiMenuFill size={size} />; // Varsayılan bir simge
  }
};
