import 'react-toastify/dist/ReactToastify.css';
import { createContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
export const MessageContext = createContext({});

export const MessageProvider = ({ children }) => {
  const messageBox = (mesaj, status, time = 3000) => {
    toast[status](mesaj, {
      position: 'top-right',
      theme: 'colored',
      autoClose: time,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false
    });
  };

  return (
    <MessageContext.Provider value={{ messageBox }}>
      <ToastContainer />
      {children}
    </MessageContext.Provider>
  );
};
