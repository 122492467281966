import { Route, Routes } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import Login from '@/content/Auth/Login';
import ChangePassword from '@/content/Auth/ChangePassword';
import Register from '@/content/Auth/Register';
import ForgetPassword from '@/content/Auth/ForgetPassword';
import VerifyCode from '@/content/Auth/VerifyCode';
import ResetPassword from '@/content/Auth/ResetPassword';

const Auth = () => {
  return (
    <Routes>
      {/* Giriş Sayfası */}
      <Route path="login" element={<Login />} />
      {/* Telefonla Giriş */}
      {/* Şifre Değişikliği */}
      <Route
        path="changePassword"
        element={
          <RequireAuth>
            <ChangePassword />
          </RequireAuth>
        }
      />
      {/* Kayıt Ol */}
      <Route path="register" element={<Register />} />
      {/* Şifre Unutma */}
      <Route path="forget-password" element={<ForgetPassword />} />
      <Route path="verify" element={<VerifyCode />} />
      <Route path="reset-password" element={<ResetPassword />} />
    </Routes>
  );
};

export default Auth;
