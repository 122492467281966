import axios from './axios';

/* eslint-disable no-bitwise */
export const JWT_SECRET = 'element';
export const JWT_EXPIRES_IN = 3600 * 24 * 2;

export const sign = (payload, privateKey, header) => {
    const now = new Date();
    header.expiresIn = new Date(now.getTime() + header.expiresIn);
    const encodedHeader = btoa(JSON.stringify(header));
    const encodedPayload = btoa(JSON.stringify(payload));
    const signature = btoa(
        Array.from(encodedPayload)
            .map((item, key) =>
                String.fromCharCode(
                    item.charCodeAt(0) ^ privateKey[key % privateKey.length].charCodeAt(0)
                )
            )
            .join('')
    );

    return `${encodedHeader}.${encodedPayload}.${signature}`;
};

export const decode = (token) => {
    const [encodedHeader, encodedPayload, signature] = token.split('.');
    const header = JSON.parse(atob(encodedHeader));
    const payload = JSON.parse(atob(encodedPayload));
    const now = new Date();

    if (now < header.expiresIn) {
        throw new Error('Expired token');
    }

    const verifiedSignature = btoa(
        Array.from(encodedPayload)
            .map((item, key) =>
                String.fromCharCode(
                    item.charCodeAt(0) ^ JWT_SECRET[key % JWT_SECRET.length].charCodeAt(0)
                )
            )
            .join('')
    );

    if (verifiedSignature !== signature) {
        throw new Error('Invalid signature');
    }

    return payload;
};

export const verify = (token, privateKey) => {

    const [ encodedPayload] = token.split('.');
    const payload = JSON.parse(atob(encodedPayload));

    let date = new Date().toUTCString();
    date = new Date(date).getTime();
    const now = Math.floor(date / 1000);

    if (payload.exp < now) {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }

    // const verifiedSignature = btoa(
    //     Array.from(encodedPayload)
    //         .map((item, key) =>
    //             String.fromCharCode(
    //                 item.charCodeAt(0) ^ privateKey[key % privateKey.length].charCodeAt(0)
    //             )
    //         )
    //         .join('')
    // );

    // if (verifiedSignature !== signature) {
    //     throw new Error('The signature is invalid!');
    // }

    return payload;
};
