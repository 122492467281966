import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import "./header.css";
import ProfileMenu from './ProfileMenu';
import UnreadNotifications from './Notifications';
import useAuth from '@/features/hooks/useAuth';
import useNotificationApi from '@/services/api/useNotificationApi';
import useMenu from '@/features/hooks/useMenu';
import DateSelection from '@/components/DateSelection';
import { renderIcon } from '@/utils/iconRenderer';

export default function Header({ showSidebarHandler, mainToggleClass }) {
    const { t } = useTranslation();
    const { SetNotification } = useNotificationApi();
    const { notifications, setNotifications } = useAuth();
    const [unreadNotifications, setUnreadNotifications] = useState([]);
    const [isShowNotifications, setIsShowNotifications] = useState(false);
    const showDateSelection = import.meta.env.VITE_APP_DATE_SELECTION;
    const [isShowProfile, setIsShowProfile] = useState(false);
    const [isShowDate, setIsShowDate] = useState(false);
    const { user } = useAuth();
    const { selectedComponentHandler } = useMenu();

    let scaleClass = isShowProfile ? "scale-100" : "";

    function handleDateClick() {
        setIsShowDate(!isShowDate);
    }

    function handleNotificationsClick() {
        setIsShowNotifications(!isShowNotifications);
    }

    useEffect(() => {
        const newUnreadNotifications = notifications.filter(notification => !notification.status);
        setUnreadNotifications(newUnreadNotifications);
    }, [notifications]);

    async function setNotificationsClick() {
        handleNotificationsClick();
        const result = await SetNotification();
        setNotifications(result);
    }

    let showProfileClass = "";
    isShowProfile ? showProfileClass = "show-profile" : showProfileClass = "";

    return (
        <header>
            <nav className={`nav position-relative gl-shadow-decorative-nav ${mainToggleClass}`}>
                <button onClick={() => showSidebarHandler(true)} className='btn p-2 ms-2 me-2 d-xl-none text-secondary'>
                    {renderIcon('menu-outline',28)}
                </button>
                <button className='btn p-0 d-lg-none'>
                    <NavLink to={"/"} className='d-block w-100 flex-column align-items-center col-3' onClick={() => selectedComponentHandler("")}>
                        <img src="/images/icons/logo.png" width={36} height={36} className="rounded-circle" alt="Logo" />
                    </NavLink>
                </button>
                {showDateSelection === "true" && <DateSelection isShowDate={isShowDate} handleDateClick={handleDateClick} />}
                <UnreadNotifications 
                    notifications={notifications} 
                    isShowNotifications={isShowNotifications} 
                    setNotificationsClick={setNotificationsClick} 
                />
                <div className={`gray-layout ${showProfileClass}`} onClick={() => setIsShowProfile(!showProfileClass)}></div>
                <div className='d-flex align-items-center gap-sm-2 ms-lg-auto'>
                    {showDateSelection === "true" && (
                        <button type="button" className="btn position-relative btn-notification text-secondary" onClick={handleDateClick}>
                            {renderIcon('calender',24)}
                        </button>
                    )}
                    <button type="button" className="btn position-relative btn-notification text-secondary" onClick={handleNotificationsClick}>
                        {renderIcon('notifications',24)}
                        {unreadNotifications.length !== 0 && (
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                {unreadNotifications.length}
                                <span className="visually-hidden">{t('OkunmamisMesajlar')}</span>
                            </span>
                        )}
                    </button>
                    <div className='btn btn-user'>
                        <img
                            src={`${user?.image}?v=${Math.random()}`}
                            width={24}
                            height={24}
                            className="rounded-circle"
                            onClick={() => setIsShowProfile(!isShowProfile)}
                            onError={(e) => {
                                e.target.src = "/images/avatar/UserNull.png";
                            }}
                            alt="User"
                        />
                        <ProfileMenu scaleClass={scaleClass} setIsShowProfile={setIsShowProfile} />
                    </div>
                </div>
            </nav>
        </header>
    );
}
