import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useCargoApi from '../../../services/api/useCargoApi';
import './aractakip.css';

export default function AracTakipYurtDisiDetail({ data, setAracList }) {
  const { t } = useTranslation();
  const [confirmMessage, setConfirmMessage] = useState(false);
  const [requestPending, setRequestPending] = useState(false);
  const [backendData, setBackendData] = useState({});
  const detailData = data.data;
  const { SetAracTakipGirisCikis, GetAracTakipYurtDisiAracList } =
    useCargoApi();

  const messages = {
    9: {
      4: t('AracCikisYapiliyor'),
      3: t('AracGirisYapiliyor')
    }
  };

  const handleClick = (islem, operasyon) => {
    setConfirmMessage(messages[islem][operasyon]);
    setBackendData({ islem, operasyon });
  };

  const fetchApi = async () => {
    setRequestPending(true);
    await SetAracTakipGirisCikis(
      detailData.aracNo,
      backendData.islem,
      backendData.operasyon
    );
    setConfirmMessage(false);
    setRequestPending(false);
    const result = await GetAracTakipYurtDisiAracList();
    setAracList(result);
  };

  const confirmHtml = confirmMessage ? (
    <div className="position-fixed top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center at-layout-container">
      <div
        className="position-fixed top-0 start-0 end-0 bottom-0"
        onClick={() => setConfirmMessage(false)}></div>
      <div className="bg-white p-4 rounded-3 gl-z-1">
        <h3 className="text-center">{detailData.aracNo}</h3>
        <p className="text-secondary text-center text-black">
          {confirmMessage}
        </p>
        <div className="d-flex gap-3">
          <button
            className="btn btn-success fw-bold at-confirm-btn"
            disabled={requestPending}
            onClick={() => fetchApi()}>
            {t('Evet')}
          </button>
          <button
            className="btn btn-danger fw-bold at-confirm-btn"
            onClick={() => setConfirmMessage(false)}>
            {t('Hayır')}
          </button>
        </div>
      </div>
    </div>
  ) : (
    ''
  );

  return (
    <>
      <div className="bg-white">
        <Table striped hover>
          <tbody>
            <tr>
              <td width={100}>{t('AracNo')}</td>
              <td width={5}>:</td>
              <td className="fw-bold">{detailData.aracNo}</td>
            </tr>
            <tr>
              <td width={100}>{t('Plaka')}</td>
              <td width={5}>:</td>
              <td className="text-muted">
                {detailData.plaka} / {detailData.plakaRomork}
              </td>
            </tr>
            <tr>
              <td width={100}>{t('SoforAdi')}</td>
              <td width={5}>:</td>
              <td className="text-muted">{detailData.soforAdi}</td>
            </tr>
            <tr>
              <td width={100}>{t('Telefon')}</td>
              <td width={5}>:</td>
              <td className="text-muted">
                {detailData.soforTelefonYurtIci} /{' '}
                {detailData.soforTelefonYurtDisi}
              </td>
            </tr>
            <tr>
              <td width={100}>{t('FirmaAdi')}</td>
              <td width={5}>:</td>
              <td className="text-muted">{detailData.nakliyeFirmasi}</td>
            </tr>
            <tr>
              <td width={100}>{t('Gumruk')}</td>
              <td width={5}>:</td>
              <td className="text-muted">{detailData.gurmukAdi}</td>
            </tr>
            <tr>
              <td width={100}>{t('GumrukCikis')}</td>
              <td width={5}>:</td>
              <td className="text-muted">
                {detailData.gumrukCikisTarih.slice(0, 10)} /{' '}
                {detailData.gumrukSaat}
              </td>
            </tr>
            <tr>
              <td width={100}>{t('Aciklama')}</td>
              <td width={5}>:</td>
              <td className="text-muted">{detailData.aciklama}</td>
            </tr>
            <tr>
              <td width={100}>{t('Depo')}</td>
              <td width={5}>:</td>
              <td className="text-muted">{detailData.depo}</td>
            </tr>
            <tr>
              <td width={100}>{t('TakipNo')}</td>
              <td width={5}>:</td>
              <td className="text-muted">{detailData.takipNo}</td>
            </tr>
            <tr>
              <td width={100}>{t('Konum')}</td>
              <td width={5}>:</td>
              <td className="text-muted">{detailData.konum}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-flex-start gap-3 mb-2">
        {/* İşlem 8 */}
        <button
          className="btn btn-danger at-btn-min-width fw-bold"
          onClick={() => handleClick(9, 3)}>
          {t('Giris')}
        </button>{' '}
        {/*Operasyon - 3*/}
        <button
          className="btn btn-success at-btn-min-width fw-bold"
          onClick={() => handleClick(9, 4)}>
          {t('Cikis')}
        </button>{' '}
        {/*Operasyon - 4*/}
      </div>
      {confirmHtml}
    </>
  );
}
