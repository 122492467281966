import { Route, Routes } from "react-router-dom";
import AracTakipDashboard from "../content/Dashboard/AracTakip";
import HavaleDashboard from "../content/Dashboard/Havale";
import CargoDashboard from "../content/Dashboard/Kargo";

const Dashboard = () => {
    return (
        <Routes>
            <Route path="/" children={<CargoDashboard />} >
                <Route path="kargo" element={<CargoDashboard />} />
                <Route path="havale" element={<HavaleDashboard />} />
                <Route path="AracTakip" element={<AracTakipDashboard />} />
            </Route>
        </Routes>
    );
}

export default Dashboard;
