import AuthContext from '@/context/JWTAuthContext';
import { useContext } from 'react';

const useAuth = () => {
  const auth = useContext(AuthContext);

  // Kullanıcı kimlik doğrulama durumu
  const isAuthenticated = !!auth.user;

  // Kullanıcı rolünü alır
  const userRole = auth.user?.role || 'Customer'; // Rol yoksa 'Customer' döndürür

  // Gerekli kimlik doğrulama verileri ile dön
  return {
    ...auth,
    isAuthenticated,
    userRole
  };
};

export default useAuth;
