import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import deJSON from './translations/de';
import enJSON from './translations/en';
import trJSON from './translations/tr';
import arJSON from './translations/ar';
import krJSON from './translations/kr';

const resources = {
  de: { translation: deJSON },
  en: { translation: enJSON },
  tr: { translation: trJSON },
  ar: { translation: arJSON },
  kr: { translation: krJSON }
};

i18n.use(initReactI18next).init({
  resources,
  keySeparator: false,
  lng: 'tr',
  fallbackLng: 'tr',
  react: {
    useSuspense: true
  },
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
