import { useState, useEffect } from 'react';
import { getRoleBasedMenuItems } from '@/utils/menuItems';
import useAuth from '@/features/hooks/useAuth';

const useMenu = () => {
  const { user } = useAuth();
  const [currentMenus, setCurrentMenus] = useState([]);

  useEffect(() => {
    if (user && user.authenticationProviderType) {
      const items = getRoleBasedMenuItems(user.authenticationProviderType);
      setCurrentMenus(items);
    }
  }, [user]);

  const selectedComponentHandler = (id) => {
    // selectedComponentId veya benzer bir state ile seçili bileşeni yönetebilirsiniz
  };

  return { currentMenus, selectedComponentHandler };
};

export default useMenu;
