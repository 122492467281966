import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './branch.css';
export default function BranchDetail({ data }) {
  const rowData = data.row.data;
  const { t } = useTranslation();

  return (
    <div className="container-flex">
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css"></link>
      <div className="d-flex justify-content-center">
        <div className="col-12">
          <div className="row row-cols-1 row-cols-md-2 g-2">
            <div className="col">
              <div className="card border-primary card-wr-bg">
                <div className="card-header d-flex bg-silver">
                  <img
                    src="/images/status/shop_market.svg"
                    className="card-img-top d-block"
                    alt="..."
                    width={36}
                    height={36}
                  />
                  <p className="card-header-text">{rowData.title}</p>
                </div>
                <div className="card-body d-grid gap-1">
                  <Table className="bordered">
                    <tbody>
                      <tr>
                        <td width={40}>{t('Telefon1')}</td>
                        <td width={5}>:</td>
                        <td> {rowData.phone1}</td>
                      </tr>
                      <tr>
                        <td width={40}>{t('Telefon2')}</td>
                        <td width={5}>:</td>
                        <td> {rowData.phone2}</td>
                      </tr>
                      <tr>
                        <td width={40}>{t('Adres')}</td>
                        <td width={5}>:</td>
                        <td> {rowData.address}</td>
                      </tr>
                      <tr>
                        <td width={40}>{t('Sehir')}</td>
                        <td width={5}>:</td>
                        <td> {rowData.sehir}</td>
                      </tr>
                      <tr>
                        <td width={40}>{t('Ulke')}</td>
                        <td width={5}>:</td>
                        <td> {rowData.ulke}</td>
                      </tr>
                      <tr>
                        <td width={40}>{t('Email')}</td>
                        <td width={5}>:</td>
                        <td> {rowData.mail}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                {rowData.whatsapp ? (
                  <div className="card-footer text-whatsapp bg-silver">
                    <a
                      className="px-4 py-2"
                      href={'https://wa.me/' + rowData.whatsapp}>
                      <i className="bi bi-whatsapp"></i>
                    </a>
                  </div>
                ) : (
                  <div className="card-footer text-dark bg-silver">
                    <div></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
