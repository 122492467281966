// src/context/ThemeContext.js
import { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const storedTheme = localStorage.getItem('theme') || 'light';
  const [theme, setTheme] = useState(storedTheme);

  const changeTheme = (newTheme) => {
    document.body.classList.remove(
      'light-mode',
      'dark-mode',
      'high-contrast-mode'
    );
    document.body.classList.add(`${newTheme}-mode`);
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    changeTheme(theme);
  }, []);

  const cycleTheme = () => {
    const newTheme =
      theme === 'light' ? 'dark' : theme === 'dark' ? 'high-contrast' : 'light';
    changeTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, changeTheme: cycleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
