import { useTranslation } from "react-i18next";
import './status-tracking.css'



function StatusTracking({ data }) {
    // const status = data;
    const status = data;
    // const status = data;
    // status1 ? showNotificationsClass = "show-notifications" : showNotificationsClass = "";
    const { t } = useTranslation();

    return (
        <div className="main_container">
            <div className="padding-bottom-3x mb-1">
                <div className="card mb-3 card-max-width-viewport">
                    <div className="card-body pt-3 px-0">
                        <div className="steps d-flex flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                            {/* completed */}
                            <div className={`step ${1 <= status ? 'completed' : ''}`}>
                                <div className="step-icon-wrap">
                                    <div className="step-icon">
                                        <img src="/images/status/box_8.svg" width={36} height={36} className={`step-img ${1 <= status ? 'gl-filter-white' : ''}`} />
                                    </div>
                                </div>
                                <p className="step-title">{t("MalKabul")}</p>
                            </div>
                            <div className={`step ${2 <= status ? 'completed' : ''}`}>
                                <div className="step-icon-wrap">
                                    <div className="step-icon">
                                        <img src="/images/status/cargo.svg" width={36} height={36} className={`step-img ${2 <= status ? 'gl-filter-white' : ''}`} />
                                    </div>
                                </div>
                                <p className="step-title">{t("Yukleme")}</p>
                            </div>
                            <div className={`step ${3 <= status ? 'completed' : ''}`}>
                                <div className="step-icon-wrap">
                                    <div className="step-icon">
                                        <img src="/images/status/security.svg" width={36} height={36} className={`step-img ${3 <= status ? 'gl-filter-white' : ''}`} />
                                        {/* <i className="pe-7s-medal" size="large"></i> */}
                                    </div>
                                </div>
                                <p className="step-title">{t("Gumruk")}</p>
                            </div>
                            <div className={`step ${4 <= status ? 'completed' : ''}`}>
                                <div className="step-icon-wrap">
                                    <div className="step-icon">
                                        {/* <i className="pe-7s-car" size="large"></i> */}
                                        <img src="/images/status/shop_market.svg" width={36} height={36} className={`step-img ${4 <= status ? 'gl-filter-white' : ''}`} />
                                    </div>
                                </div>
                                <p className="step-title">{t("Depo")}</p>
                            </div>
                            <div className={`step ${5 <= status ? 'completed' : ''}`}>
                                <div className="step-icon-wrap">
                                    <div className="step-icon">
                                        <img src="/images/status/basket_cart_3.svg" width={36} height={36} className={`step-img ${5 <= status ? 'gl-filter-white' : ''}`} />
                                        {/* <i className="pe-7s-home"></i> */}
                                    </div>
                                </div>
                                <p className="step-title">{t("Teslim")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
export default StatusTracking;