const deJSON = {
  aciklama: 'Beschreibung',
  add: 'Addieren',
  added: 'Erfolgreich hinzugefügt.',
  address: 'Adresse',
  adiSoyadi: 'Name und Nachname',
  adres: 'Adresse',
  agustos: 'August',
  alacak: 'empfangbar',
  alias: 'Anzeigename',
  aliciAdi: 'Empfängername',
  aliciKodu: 'Empfängercode',
  appMenu: 'Anwendung',
  arabic: 'Arabisch',
  aracNo: 'Fahrzeugnummer',
  aracTakip: 'Fahrzeugortung',
  aracTakipGumruk: 'Fahrzeugverfolgung Zoll',
  aracTakipYurtDisi: 'Fahrzeugortung',
  aralik: 'Dezember',
  araToplam: 'Zwischensumme',
  authorizationsDenied:
    'Es wurde festgestellt, dass Sie versuchen, auf eine nicht autorisierte Domäne zuzugreifen.',
  bakiye: 'Balance',
  altBakiye: 'Unterkonto',
  bekleme: 'Warte ab',
  bildirimler: 'Benachrichtigungen',
  birHataOlustu: 'Etwas ist schief gelaufen',
  borc: 'Schulden',
  bosaltma: 'Entladung',
  buAy: '1 Monat',
  bugun: 'Heute',
  buHafta: 'Diese Woche',
  buYil: '1 Jahr',
  changePassword: 'Passwort ändern',
  cikis: 'Ausgang',
  citizenNumber: 'Staatsbürgerschaftsnummer',
  code: 'Code',
  confirmPassword: 'Bestätige das Passwort',
  couldNotBeVerifyCid: 'ID konnte nicht verifiziert werden.',
  create: 'Neu',
  degistir: 'Ändern',
  delete: 'löschen',
  deleteConfirm: 'Bist du sicher?',
  deleted: 'Erfolgreich gelöscht.',
  depo: 'Lagerhaus',
  description: 'Beschreibung',
  dil: 'Zunge',
  doctors: 'Arzt',
  doviz: 'Fremdwährung',
  durum: 'Lage',
  ekim: 'Oktober',
  email: 'Email',
  english: 'Englisch',
  epostaAdresi: 'E-Mail-Addresse',
  evrakNo: 'Dokumentnummer',
  eylul: 'September',
  faturaBilgileri: 'Abrechnungsdaten',
  firmaAdi: 'Firmenname',
  fullName: 'Vollständiger Name',
  geciciSifre: 'Temporäres Passwort',
  geri: 'Zurück',
  german: 'Deutsch',
  giris: 'Anmeldung',
  girisEkraninaYonlendiriliyor: 'Weiterleitung zum Anmeldebildschirm',
  girisYap: 'Anmeldung',
  gonderenAdi: 'Absender',
  gonderilenHavale: 'Überweisung gesendet',
  groupList: 'Gruppenliste',
  groupName: 'Gruppenname',
  groups: 'Gruppen',
  grupPermissions: 'Gruppenberechtigungen',
  gsm: 'Handy',
  gumruk: 'Zoll',
  gumrukCikis: 'Verlassen Sie den Zoll',
  hakkimizda: 'über uns',
  hakkinda: 'Über',
  havale: 'Überweisen',
  havaleHesap: 'Konto übertragen',
  havaleListesi: 'Überweisungsliste',
  haziran: 'Juni',
  invalidCode: 'Sie haben einen ungültigen Code eingegeben!',
  id: 'Registrationsnummer',
  iletisim: 'Kommunikation',
  iptal: 'Abbrechen',
  isim: 'Name',
  kampanya: 'Angebot',
  kapSayisi: 'Die Anzahl der Behälter',
  kargo: 'Ladung',
  hesap: 'Konto',
  hesapEkstresi: 'Kontoauszug',
  urunListesi: 'Produktliste',
  urunTakip: 'Produktverfolgung',
  kasim: 'November',
  kaydet: 'Speichern',
  kayitNo: 'Registrationsnummer',
  kayitOl: 'Registrieren',
  kg: 'kg',
  konum: 'Standort',
  kullaniciAdi: 'Nutzername',
  kurdish: 'kurdisch',
  langCode: 'Sprachcode',
  languageList: 'Sprachenliste',
  languages: 'Sprachen',
  login: 'Anmeldung',
  logList: 'Transaktionsprotokoll',
  m3: 'M3',
  m3Kg: 'M3 / kg',
  mail: 'Email',
  malKabul: 'Warenannahme',
  management: 'Verwaltung',
  mart: 'Marsch',
  mayis: 'Mai',
  menu: 'Menü',
  mevcutSifre: 'Aktuelles Passwort',
  mobilePhones: 'Handy',
  name: 'Vorname',
  nameAlreadyExist: 'Das Objekt, das Sie erstellen möchten, existiert bereits.',
  nisan: 'April',
  noRecordsFound: 'Keine Aufzeichnungen gefunden',
  notes: 'Anmerkungen',
  ocak: 'Januar',
  ok: 'OK',
  okundu: 'Lesen',
  okunmamisMesajlar: 'ungelesene Nachrichten',
  onaylandi: 'Zugelassen',
  onerSikayet: 'Vorschlag Beschwerde',
  operationClaim: 'Operative Befugnisse',
  operationClaimExists: 'Diese Betriebserlaubnis liegt bereits vor.',
  operationClaimList: 'Berechtigungsliste',
  ödendi: 'Bezahlt',
  password: 'Passwort',
  passwordDigit: 'Muss mindestens 1 Ziffer enthalten!',
  passwordEmpty: 'Passwort kann nicht leer sein!',
  passwordError:
    'Anmeldeinformationen konnten nicht authentifiziert werden, Benutzername und/oder Passwort sind falsch.',
  passwordLength: 'Muss mindestens 8 Zeichen lang sein!',
  passwordLowercaseLetter: 'Muss mindestens 1 Kleinbuchstaben enthalten!',
  passwordSpecialCharacter: 'Muss mindestens 1 Symbol enthalten!',
  passwordUppercaseLetter: 'Muss mindestens 1 Großbuchstaben enthalten!',
  permissions: 'Berechtigungen',
  phone1: 'Telefon-1',
  phone2: 'Telefon-2',
  phone3: 'Telefon-3',
  plaka: 'Nummernschild',
  profil: 'Profil',
  rapor: 'Prüfbericht',
  required: 'Dieses Feld wird benötigt!',
  saha: 'Bereich',
  save: 'Speichern',
  sehir: 'Stadt',
  sendMobileCode:
    'Bitte geben Sie den Code ein, der Ihnen per SMS zugesendet wurde!',
  sendPassword: 'Ihr neues Passwort wurde an Ihre E-Mail-Adresse gesendet.',
  sifirla: 'Zurücksetzen',
  sifre: 'Passwort',
  sifreDegistir: 'Passwort ändern',
  sifreEslesmiyor: 'Passwort stimmt nicht überein',
  sifremiUnuttum: 'Ich habe mein Passwort vergessen',
  sifremVar: 'Ich habe ein Passwort',
  sifrenizDahaOnceGonderildi: 'Ihr Passwort wurde bereits versendet',
  sifreTelefonaGonderildi: 'Ihr Passwort wurde an das Telefon gesendet',
  sifreYenile: 'Passwort zurücksetzen',
  sifreyiDogrula: 'Passwort bestätigen',
  sira: 'Befehl',
  smsServiceNotFound: 'Der SMS-Dienst kann nicht erreicht werden.',
  soforAdi: 'Fahrername',
  stokAdi: 'Bestandsname',
  stringLengthMustBeGreaterThanThree:
    'Bitte geben Sie einen Ausdruck mit mindestens 3 Zeichen ein.',
  subat: 'Februar',
  subeListesi: 'Filialliste',
  successfulLogin: 'Die Anmeldung am System ist erfolgreich.',
  takipNo: 'Auftragsnummer, Frachtnummer, Sendungscode',
  talimatListesi: 'Anweisungsliste',
  tarih: 'Geschichte',
  tarihTutar: 'Datum - Betrag',
  telefon: 'Telefon',
  telefon1: 'Telefon-1',
  telefon2: 'Telefon-2',
  telefonVeyaEmail: 'Telefon oder E-Mail',
  temmuz: 'Juli',
  teslim: 'meine Lieferung',
  tipi: 'Art',
  title: 'Titel',
  tokenProviderException: 'Token-Provider darf nicht leer sein!',
  translateList: 'Sprachübersetzungsliste',
  translateWords: 'Sprachübersetzungen',
  trueButCellPhone: 'Die Angaben sind korrekt. Handy erforderlich.',
  tumunuGoruntule: 'Alle ansehen',
  turkish: 'Türkisch',
  tutar: 'Menge',
  ulke: 'Land',
  unknown: 'Unbekannt!',
  unvan: 'Titel',
  update: 'Aktualisieren',
  updated: 'Erfolgreich aktualisiert.',
  userList: 'Benutzerliste',
  userNotFound:
    'Anmeldeinformationen konnten nicht überprüft werden. Bitte verwenden Sie den Neuregistrierungsbildschirm.',
  users: 'Benutzer',
  usersClaims: 'Berechtigungen des Benutzers',
  usersGroups: 'Benutzergruppen',
  uyeOl: 'Anmelden',
  value: 'Wert',
  vergiDairesi: 'Steuerverwaltung',
  vergiNumarasi: 'Steuernummer',
  verifyCid: 'ID verifiziert.',
  wrongCitizenId:
    'Staatsbürgerschaftsnummer nicht in unserem System gefunden. Bitte erstellen Sie eine neue Registrierung!',
  yeniSifre: 'Neues Kennwort',
  yukleme: 'Wird geladen',
  zatenKayitli: 'Bereits registriert',
  hesabiSil: 'Konto löschen',
  takipEt: 'Verfolgen',
  depoBakiye: 'Lagerbestand',
  detay: 'Detail',
  paketSayisi: 'Pakete',
  havaleBakiye: 'Überweisungsguthaben',
  nakitOdeme: 'Barzahlung',
  nakliyeOdeme: 'Frachtzahlung',
  komisyonOdeme: 'Kommission Zahlung',
  kasapHesap: 'Metzgerkonto',
  evHesap: 'Hauskonto',
  bankaDinarOdeme: 'Bank Dinar Zahlung',
  musteriKodu: 'Mandant-Code',
  UserCode: 'Benutzercode',
  AcountDelete: 'Konto löschen',
  Location: 'Standort',
  Yes: 'Ja',
  No: 'Nein',
  Warning: 'Warnung',
  RecordLocationInformation: 'Standortinformationen aufzeichnen?',
  DoYouWantToDeleteTheAccount: 'Möchten Sie das Konto löschen?',
  GetAddressWithLocation: 'Adresse mit Standort erhalten',
  LocationInformation: 'Standortinformationen',
  LocationInformationRecorded: 'Standortinformationen aufgezeichnet',
  LocationInformationNotRecorded: 'Standortinformationen nicht aufgezeichnet',
  LocationSaveInformation: 'Standortinformationen speichern',
  Dashboard: 'Instrumententafel',
  OrderList: 'Bestellliste',
  MainAcount: 'Hauptkonto',
  SubAcount: 'Unterkonto',
  SendCode: 'Code senden',
  Lutfen6HaneliDogrulamaKodunuGiriniz:
    'Bitte geben Sie den 6-stelligen Bestätigungscode ein.',
  LutfenSizeGonderilenDogrulamaKodunuGirin:
    'Bitte geben Sie den Ihnen zugesandten Bestätigungscode ein.',
  Confirm: 'Bestätigen'
};

export default deJSON;
