import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../features/hooks/useAuth';

const RequireAuth = ({ children }) => {
  let { isAuthenticated } = useAuth();
  let location = useLocation();

  if (!isAuthenticated) {
    // Kullanıcı giriş yapmamışsa, login sayfasına yönlendirin
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  // Kullanıcı giriş yaptıysa, bileşeni göster
  return children;
};

export default RequireAuth;
