const enJSON = {
  Aciklama: 'Description',
  Add: 'Add',
  Added: 'Added Successfully.',
  Address: 'Address',
  AdiSoyadi: 'Name and surname',
  Adres: 'Address',
  Agustos: 'August',
  Alacak: 'receivable',
  Alias: 'Alias',
  AliciAdi: 'Recipient Name',
  AliciKodu: 'Recipient Code',
  AppMenu: 'Application',
  Arabic: 'Arabic',
  AracNo: 'Vehicle Number',
  AracTakip: 'Vehicle Tracking',
  AracTakipGumruk: 'Vehicle Tracking Customs',
  AracTakipYurtDisi: 'Vehicle Tracking',
  Aralik: 'December',
  AraToplam: 'Subtotal',
  AuthorizationsDenied:
    'It has been detected that you are trying to enter an unauthorized domain.',
  Bakiye: 'Balance',
  AltBakiye: 'Sub Balance',
  Bekleme: 'Wait',
  Bildirimler: 'Notifications',
  BirHataOlustu: 'Something went wrong',
  Borc: 'Debt',
  Bosaltma: 'unloading',
  BuAy: '1 month',
  Bugun: 'Today',
  BuHafta: 'This week',
  BuYil: '1 year',
  ChangePassword: 'Change Password',
  Cikis: 'Exit',
  CitizenNumber: 'Citizenship Number',
  Code: 'Code',
  ConfirmPassword: 'Confirm Password',
  CouldNotBeVerifyCid: 'Failed to Verify ID.',
  Create: 'new',
  Degistir: 'Change',
  Delete: 'delete',
  DeleteConfirm: 'Are you sure?',
  Deleted: 'Deleted Successfully.',
  Depo: 'Warehouse',
  Description: 'Description',
  Dil: 'Tongue',
  Doctors: 'Doctor',
  Doviz: 'Foreign currency',
  Durum: 'Situation',
  Ekim: 'October',
  Email: 'Email',
  English: 'English',
  EpostaAdresi: 'E-mail address',
  EvrakNo: 'Document number',
  Eylul: 'September',
  FaturaBilgileri: 'Billing Information',
  FirmaAdi: 'Firm Name',
  FullName: 'Full name',
  GeciciSifre: 'Temporary Password',
  Geri: 'Back',
  German: 'German',
  Giris: 'Login',
  GirisEkraninaYonlendiriliyor: 'Redirecting to Login Screen',
  GirisYap: 'Login',
  GonderenAdi: 'Sender Name',
  GonderilenHavale: 'Remittance Sent',
  GroupList: 'Group List',
  GroupName: 'Group Name',
  Groups: 'Groups',
  GrupPermissions: 'Group Permissions',
  Gsm: 'Mobile phone',
  Gumruk: 'Customs',
  GumrukCikis: 'Customs Exit',
  Hakkimizda: 'about us',
  Hakkinda: 'About',
  Havale: 'Transfer',
  HavaleHesap: 'Transfer Account',
  HavaleListesi: 'Transfer List',
  Haziran: 'June',
  InvalidCode: 'You Have Entered an Invalid Code!',
  id: 'Registration number',
  iletisim: 'Communication',
  iptal: 'cancel',
  isim: 'Name',
  Kampanya: 'Offer',
  KapSayisi: 'The number of container',
  Kargo: 'Gonderi',
  Hesap: 'Hesap',
  HesapEkstresi: 'Account Statement',
  UrunListesi: 'Shipping list',
  UrunTakip: 'Product Tracking',
  Kasim: 'November',
  Kaydet: 'Save',
  KayitNo: 'Registration number',
  KayitOl: 'Register',
  Kg: 'kg',
  konum: 'Location',
  KullaniciAdi: 'User name',
  Kurdish: 'Kurdish',
  LangCode: 'Language Code',
  LanguageList: 'Language List',
  Languages: 'Languages',
  Login: 'Login',
  LogList: 'Transaction Log',
  M3: 'M3',
  M3Kg: 'M3 / Kg',
  mail: 'E-mail',
  MalKabul: 'Goods Acceptance',
  Management: 'management',
  Mart: 'March',
  Mayis: 'May',
  Menu: 'Menu',
  MevcutSifre: 'Current password',
  MobilePhones: 'Mobile phone',
  Name: 'First Name',
  NameAlreadyExist: 'The object you are trying to create already exists.',
  Nisan: 'April',
  NoRecordsFound: 'No Records Found',
  Notes: 'Notes',
  Ocak: 'January',
  Ok: 'OK',
  Okundu: 'Read',
  OkunmamisMesajlar: 'Unread Messages',
  Onaylandı: 'Approved',
  OneriSikikayet: 'Suggestion Complaint',
  OperationClaim: 'Operational Powers',
  OperationClaimExists: 'This operation permit already exists.',
  OperationClaimList: 'Authorization List',
  Ödendi: 'Paid',
  Password: 'Password',
  PasswordDigit: 'Must Contain At least 1 Digit!',
  PasswordEmpty: 'Password cannot be empty!',
  PasswordError:
    'Failed to Authenticate Credentials, Username and/or password are incorrect.',
  PasswordLength: 'Must be Minimum 8 Characters Long!',
  PasswordLowercaseLetter: 'Must Contain At least 1 Lowercase Letter!',
  PasswordSpecialCharacter: 'Must Contain At Least 1 Icon!',
  PasswordUppercaseLetter: 'Must Contain At least 1 Capital Letter!',
  Permissions: 'permissions',
  phone1: 'Phone-1',
  phone2: 'Phone-2',
  phone3: 'Phone-3',
  Plaka: 'Number plate',
  Profil: 'Profile',
  Rapor: 'Report',
  Required: 'This field is required!',
  Saha: 'Field',
  Save: 'Save',
  Sehir: 'City',
  SendMobileCode: 'Please Enter the Code Sent to You as an SMS!',
  SendPassword: 'Your new password has been sent to your e-mail address.',
  Sifirla: 'Reset',
  Sifre: 'Password',
  SifreDegistir: 'Change Password',
  SifreEslesmiyor: 'Password does not match',
  SifremiUnuttum: 'I forgot my password',
  SifremVar: 'I have a password',
  SifrenizDahaOnceGonderildi: 'Your password has already been sent',
  SifreTelefonaGonderildi: 'Your password has been sent to the phone',
  SifreYenile: 'Reset Password',
  SifreyiDogrula: 'Verify Password',
  Sira: 'Order',
  SmsServiceNotFound: 'Unable to reach SMS Service.',
  SoforAdi: 'Driver Name',
  StokAdi: 'Stock Name',
  StringLengthMustBeGreaterThanThree:
    'Please enter an expression of at least 3 characters.',
  Subat: 'February',
  SubeListesi: 'Branch List',
  SuccessfulLogin: 'Login to the system is successful.',
  TakipNo: 'Tracking Number',
  TalimatListesi: 'Instruction List',
  Tarih: 'History',
  TarihTutar: 'Date - Amount',
  Telefon: 'Phone',
  Telefon1: 'Phone-1',
  Telefon2: 'Phone-2',
  TelefonVeyaEmail: 'Phone or Email',
  Temmuz: 'July',
  Teslim: 'my delivery',
  Tipi: 'Type',
  title: 'Title',
  TokenProviderException: 'Token cannot be empty!',
  TranslateList: 'Language Translation List',
  TranslateWords: 'Language Translations',
  TrueButCellPhone: 'The information is correct. Cell phone required.',
  TumunuGoruntule: 'View All',
  Turkish: 'Turkish',
  Tutar: 'Amount',
  Ulke: 'Country',
  Unknown: 'Unknown!',
  Unvan: 'Title',
  Update: 'Update',
  Updated: 'Successfully Updated.',
  UserList: 'User List',
  UserNotFound:
    'Failed to Verify Credentials. Please use the New Registration Screen.',
  Users: 'Users',
  UsersClaims: 'Users Authorizations',
  UsersGroups: 'Users Groups',
  UyeOl: 'Sign up',
  Value: 'Value',
  VergiDairesi: 'Tax Administration',
  VergiNumarası: 'Tax number',
  VerifyCid: 'ID Verified.',
  WrongCitizenId:
    'User Not Found in Our System. Please Create New Registration!',
  YeniSifre: 'new password',
  Yukleme: 'Loading',
  ZatenKayitli: 'Already Registered',
  HesabiSil: 'Delete Account',
  TakipEt: 'Follow',
  DepoBakiye: 'Warehouse Balance',
  Detay: 'Detail',
  PaketSayisi: 'Packages',
  HavaleBakiye: 'Transfer Balance',
  NakitOdeme: 'Cash Payment',
  NakliyeOdeme: 'Shipment Payment',
  KomisyonOdeme: 'Commission Payment',
  KasapHesap: 'Butcher Account',
  EvHesap: 'Home Account',
  BankaDinarOdeme: 'Bank Dinar Payment',
  MusteriKodu: 'Customer Code',
  UserCode: 'User Code',
  AcountDelete: 'Delete Account',
  Location: 'Location',
  Yes: 'Yes',
  No: 'No',
  Warning: 'Warning',
  RecordLocationInformation: 'Record Location Information?',
  DoYouWantToDeleteTheAccount: 'Do you want to delete the account?',
  GetAddressWithLocation: 'Get Address With Location',
  LocationInformation: 'Location Information',
  LocationInformationRecorded: 'Location Information Recorded',
  LocationInformationNotRecorded: 'Location Information Not Recorded',
  LocationSaveInformation: 'Save Location Information',
  Dashboard: 'Dashboard',
  OrderList: 'Order List',
  MainAcount: 'Main Account',
  SubAcount: 'Sub Account',
  SendCode: 'Send Code',
  Lutfen6HaneliDogrulamaKodunuGiriniz:
    'Please enter the 6-digit verification code.',
  LutfenSizeGonderilenDogrulamaKodunuGirin:
    'Please enter the verification code sent to you.',
  Confirm: 'Confirm'
};

export default enJSON;
