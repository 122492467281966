import { useTranslation } from 'react-i18next';
import './notifications.css'
import { renderIcon } from '@/utils/iconRenderer';

function UnreadNotifications({ notifications, isShowNotifications, setNotificationsClick }) {
    let showNotificationsClass = "";
    isShowNotifications ? showNotificationsClass = "show-notifications" : showNotificationsClass = "";
    const { t } = useTranslation();

    return (
        <>
            <div className={`gray-layout ${showNotificationsClass}`} onClick={() => setNotificationsClick()}></div>
            <div className={`notifications-container bg-light ${showNotificationsClass}`}>
                <div className='p-1 bg-color-primary bg-gradient rounded m-2 gl-page-header-container'>
                    {renderIcon('notifications', 30)}
                    <p className='m-0 fs-5'>{t('Bildirimler')}</p>
                </div>
                <div className='notifications-row bg-light'>
                    {notifications.map(notification => {
                        return (
                            <div className='d-flex p-2 pt-2 bg-light m-1 rounded shadow align-items-center' key={notification.reCno} >
                                <div>
                                    <div className='d-flex align-items-center mb-1'>
                                        <img src={notification.image} width={24} height={24}></img>
                                        <p className='m-0 ms-1 notification-title'>{notification.title}</p>
                                    </div>
                                    <p className='m-0 notification-detail'>{notification.detail}</p>
                                </div>
                                {!notification.status && <span className="translate-middle rounded-pill notification-status bg-color-primary d-block"></span>}
                            </div>
                        )
                    })}
                </div>
                <div className="d-grid justify-content-center mx-auto p-4 border-top">
                    <button className='btn btn-outline-primary btn-sm btn text-center d-flex align-items-center justify-content-center'>{t('TumunuGoruntule')}
                        <svg xmlns="http://www.w3.org/2000/svg" className='ms-1' width={18} height={18} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                        </svg>
                    </button>
                </div>
            </div>
        </>
    );
}

export default UnreadNotifications;
