import useMessage from '@/features/hooks/useMessage';
import apiClient from '@/utils/axios';

export default function SetUserApi() {
  const { messageBox } = useMessage();

  const ForgotPassword = async (data) => {
    try {
      const response = await apiClient.post('/api/user/forgot-password', {
        UserName: data.UserName,
        MobilePhone: data.MobilePhone
      });
      const result = response.data;
      return result;
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        'Şifre sıfırlama işlemi sırasında bir hata oluştu.';
      messageBox(errorMessage, 'error');
      return null;
    }
  };
  const VerifyCode = async (data) => {
    try {
      const response = await apiClient.post('/api/user/verify-code', {
        UserName: data.UserName,
        Code: data.Code
      });
      const result = response.data;
      return result;
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || 'Kod doğrulanırken bir hata oluştu.';
      messageBox(errorMessage, 'error');
      return null;
    }
  };
  const ResetPassword = async (data) => {
    try {
      const response = await apiClient.post('/api/user/reset-password', {
        UserName: data.UserName,
        Code: data.Code,
        NewPassword: data.NewPassword
      });
      const result = response.data;
      return result;
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || 'Şifre sıfırlanırken bir hata oluştu.';
      messageBox(errorMessage, 'error');
      return null;
    }
  };
  const SendVerifyCode = async (phone) => {
    try {
      const response = await apiClient.post('/api/user/send-verify-code', {
        phone: phone // Burada telefon numarasını doğrudan kullanıyoruz
      });
      const result = response.data;
      return result;
    } catch (err) {
      messageBox(err?.message, 'error');
      return null;
    }
  };
  const LoginPhone = async (data) => {
    try {
      const response = await apiClient.post('/api/user/login-phone', {
        Phone: data.Phone,
        Code: data.Code
      });
      const result = response.data;
      return result;
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || 'Telefon ile girişte bir hata oluştu.';
      messageBox(errorMessage, 'error');
      return null;
    }
  };
  return {
    ForgotPassword,
    VerifyCode,
    ResetPassword,
    SendVerifyCode,
    LoginPhone
  };
}
