import { useState } from 'react';
import { CiLogin } from 'react-icons/ci';
import { useTranslation } from 'react-i18next';
import UsePhoneInput from '@/components/Use/UsePhoneInput/UsePhoneInput';
import { useNavigate } from 'react-router-dom';
import SetUserApi from '@/services/api/setUserApi';

const PhoneLoginForm = ({ requestPending }) => {
  const [phone, setPhone] = useState('');
  const { t } = useTranslation();
  const { SendVerifyCode } = SetUserApi();
  const navigate = useNavigate(); // useNavigate kancasını burada çağırın

  const isSubmit = phone.trim();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('handleSubmit');
    const response = await SendVerifyCode(phone);
    if (response?.success) {
      console.log('Navigate');
      navigate('/auth/verify', {
        state: { userName: phone, phoneLoginFL: true }
      }); // Navigate yerine navigate kullan
    }
  };

  return (
    <form onSubmit={handleSubmit} className="d-grid">
      {/* PhoneInput Bileşeni */}
      <div className="mx-3 my-3">
        <div className="input-group">
          <UsePhoneInput value={phone} onChange={setPhone} />
        </div>
      </div>

      {/* Giriş Butonu */}
      <div className="btn-container">
        <button
          className="btn-login"
          type="submit"
          disabled={!isSubmit || requestPending}>
          <CiLogin size={28} fontWeight={600} color="white" />
          <p className="m-0 mx-2">{t('Giris')}</p>
        </button>
      </div>
    </form>
  );
};

export default PhoneLoginForm;
