import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './styles.css';
import './thema.css';
import './components.css';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/JWTAuthContext';
import { MessageProvider } from './context/MessageContext';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes/router';
import { DateSelectionProvider } from './context/DateSelectionContext';
import { StrictMode } from 'react';
import { LoadingProvider } from './context/LoadingContext';
import { MenuProvider } from './context/MenuContext';
import { ThemeProvider } from './context/ThemeContext';

// Root elemanını oluşturun
const container = document.getElementById('root');
const root = createRoot(container);

// Uygulamayı render edin
root.render(
  <ThemeProvider>
    <AuthProvider>
      <MenuProvider>
        <StrictMode>
          <MessageProvider>
            <LoadingProvider>
              <DateSelectionProvider>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </DateSelectionProvider>
            </LoadingProvider>
          </MessageProvider>
        </StrictMode>
      </MenuProvider>
    </AuthProvider>
  </ThemeProvider>
);

reportWebVitals();
