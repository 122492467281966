import  { useState } from 'react'
import { useTranslation } from 'react-i18next';
import './changelangulage.css'
import internationalization from '../../../../assets/i18n/i18n';
import useAuth from '../../../../features/hooks/useAuth';


export default function ChangeLanguage() {
    const { t } = useTranslation();
    const [showSelectLang, setShowSelectLang] = useState(false);
    const { SetUserLanguage, user } = useAuth();

    const showLang = showSelectLang ? "show-lang" : "";

    const lang = user?.language ? user.language : "tr"


    const changeLang = async (e) => {
        const lang = e.currentTarget.value;
        internationalization.changeLanguage(lang);
        await SetUserLanguage(lang);
        setShowSelectLang(false);
    }

    return (
        <>
            <a className="px-4 py-2 text-center d-flex align-items-center justify-content-between text-hover black-color mx-2 rounded-pill" onClick={() => setShowSelectLang(!showSelectLang)}>
                <div className='d-flex align-items-center gap-2'>
                    <img src={`/images/icons/${lang}.png`} width={20}></img>
                    {t("Dil")}
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                </svg>
            </a>
            <div className={`lang-container ${showLang}`}>
                <button value="tr" className='text-hover btn d-flex justify-content-between align-items-center w-100 p-2 rounded-pill' onClick={(e) => changeLang(e)}><span>{t('Tr')}</span><img src='/images/icons/tr.png' width={20}></img></button>
                <button value="ar" className='text-hover btn d-flex justify-content-between align-items-center w-100 p-2 rounded-pill' onClick={(e) => changeLang(e)}><span>{t('Ar')}</span><img src='/images/icons/ar.png' width={20}></img></button>
                <button value="en" className='text-hover btn d-flex justify-content-between align-items-center w-100 p-2 rounded-pill' onClick={(e) => changeLang(e)}><span>{t('En')}</span><img src='/images/icons/en.png' width={20}></img></button>
                <button value="de" className='text-hover btn d-flex justify-content-between align-items-center w-100 p-2 rounded-pill' onClick={(e) => changeLang(e)}><span>{t('De')}</span><img src='/images/icons/de.png' width={20}></img></button>
                <button value="kr" className='text-hover btn d-flex justify-content-between align-items-center w-100 p-2 rounded-pill' onClick={(e) => changeLang(e)}><span>{t('Kr')}</span><img src='/images/icons/kr.png' width={20}></img></button>
            </div>
        </>
    )
}
