export const faturaBilgileri =
{
    "Unvan": "Altuntur Nak. İnş. İç ve Dış. Ltd. Şti.",
    "VergiDairesi": "ŞEHİTKAMİL",
    "VergiNumarası": "068 011 8282",
    "Adres": "Aydınlar Mah. 03063 Nolu Cad. No 25/1 ( Altunkaynak Plaza ) Şehitkâmil, Gaziantep, Turkey",
    "Telefon": "+90 342 231 17 20",
}





