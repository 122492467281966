import Layout from '../../Layouts';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useDate from '../../../features/hooks/useDate';
import { DataGrid } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import '../../../assets/css/devexpress/dx.common.css';
import '../../../assets/css/devexpress/dx.material.blue.light.css';
import KargoListesiDetail from './KargoListesiDetail';
import useCargoApi from '../../../services/api/useCargoApi';
import useAuth from '../../../features/hooks/useAuth';
import TabNavigation from '../../../components/TabNavigation';
import { useLoading } from '@/context/LoadingContext';

function KargoListesi() {
  const { setLoading } = useLoading();
  const { t } = useTranslation();
  const { GetCargoGonderiList } = useCargoApi();
  const [subAccount, setSubAccount] = useState(0);
  const { user } = useAuth();

  const [data, setData] = useState('');
  const { ilkTarih, sonTarih } = useDate();

  useEffect(() => {
    setLoading(true);
    async function getData() {
      const result = await GetCargoGonderiList(ilkTarih, sonTarih, subAccount);
      setData(result);
    }

    getData();
    setLoading(false);
    return () => {
      setData('');
    };
  }, [ilkTarih, sonTarih, subAccount]);

  return (
    <Layout>
      {user?.subAccount == 1 && (
        <TabNavigation
          activeTab={subAccount}
          setActiveTab={(value) => setSubAccount(value)}
        />
      )}
      <ScrollView direction="horizontal">
        <DataGrid
          dataSource={data}
          allowColumnReordering={false}
          scrollingEnabled={false}
          // defaultSelectedRowKeys={0}
          columnAutoWidth={true}
          focusedRowEnabled={true}
          keyExpr="kayitNo"
          // onContentReady={(e) => contentReady(e)}
        >
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={true} />
          <FilterRow visible={true} />
          {/* <Selection mode={'multiple'} /> */}
          <HeaderFilter visible={true} />

          {/* <Column dataField={'gonderenAdi'} /> */}
          <Column
            caption={t('GonderenAdi')}
            dataField={'gonderenAdi'}
            width={200}
          />
          <Column
            caption={t('MusteriKodu')}
            dataField={'musteriKodu'}
            width={120}
          />
          <Column
            caption={t('AracNo')}
            dataField={'aracNo'}
            dataType={'string'}
            width={125}
          />
          <Column
            dataField={'tarih'}
            caption={t('Tarih')}
            dataType={'date'}
            format={'dd.MM.yyyy'}
            // selectedFilterOperation={'>='}
            width={100}
          />
          <Column
            caption={t('M3')}
            dataField={'m3'}
            format="#,##0.00"
            // selectedFilterOperation={'>='}
            // filterValue={0}
            width={125}
          />
          <Column
            caption={t('KapSayisi')}
            dataField={'kapSayisi'}
            format="#,##0.00"
            // selectedFilterOperation={'>='}
            // filterValue={0}
            width={125}
          />
          <Column
            caption={t('Kg')}
            dataField={'kg'}
            format="#,##0.00"
            // selectedFilterOperation={'>='}
            // filterValue={0}
            width={125}
          />
          <Column caption={t('StokAdi')} dataField={'stokAdi'} width={150} />
          <Column
            dataField={'kayitNo'}
            caption={t('KayitNo')}
            allowSorting={false}
            allowFiltering={true}
            allowGrouping={false}
            allowReordering={false}
            width={100}
            visible={false}
            // headerFilter={true}
          />
          {/* <Editing
                        mode="row"
                        useIcons={true}
                        allowUpdating={true}
                        width={100}
                    />
                    <Column enabled={true} /> */}
          <Summary>
            <TotalItem column="gonderenAdi" summaryType="count" />
            <TotalItem
              column="m3"
              summaryType="sum"
              valueFormat="#,###,##0.00"
            />
            <TotalItem column="kapSayisi" summaryType="sum" />
            <TotalItem
              column="kg"
              summaryType="sum"
              valueFormat="#,###,##0.00"
            />
          </Summary>

          <Pager
            allowedPageSizes={[25, 50, 100, 1000]}
            showPageSizeSelector={true}
          />
          <Paging defaultPageSize={25} />
          {/* <Scrolling mode={HorizontalLine} /> */}
          {/* <ScrollBar mode={HorizontalLine} /> */}
          <MasterDetail
            enabled={true}
            render={(e) => <KargoListesiDetail data={e} />}
          />
        </DataGrid>
      </ScrollView>
    </Layout>
  );
}

export default KargoListesi;
