import { useState } from 'react';
import { CiLogin } from 'react-icons/ci';
import { useTranslation } from 'react-i18next';
import { renderIcon } from '@/utils/iconRenderer';

const AcountLoginForm = ({ onSubmit, requestPending }) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  const isSubmit = userName.trim() && password.trim();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(userName, password); // login işlemi üst bileşende yapılacak
  };

  return (
    <form onSubmit={handleSubmit} className="d-grid">
      <div className="mb-3">
        <div className="input-group">
          <div className="bg-color-layout-shadow d-flex align-items-center p-1 px-2">
            <span className="black-color">
              {renderIcon('user', 22, 'text-color-black')}
            </span>
          </div>
          <input
            className="formControl-text form-control"
            id="username"
            type="text"
            placeholder={t('UserCode')}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <div className="bg-color-layout-shadow d-flex align-items-center p-1 px-2">
            <span className="black-color">
              {renderIcon('lock-password-line', 22, 'text-color-black')}
            </span>
          </div>
          <input
            className="formControl-text form-control"
            id="password"
            type="password"
            placeholder={t('Sifre')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="btn-container">
        <button
          className="btn-login"
          type="submit"
          disabled={!isSubmit || requestPending}>
          <CiLogin
            size={28}
            fontWeight={600}
            color="white"
            className="mx-2 my-0"
          />
          {t('Giris')}
        </button>
      </div>
    </form>
  );
};

export default AcountLoginForm;
