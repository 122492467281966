//useHavaleApi.jsx
import useMessage from '@/features/hooks/useMessage';
import apiClient from '@/utils/axios';

export default function useHavaleApi() {
  const { messageBox } = useMessage();

  const GetHavaleHesapList = async (ilkTarih, sonTarih) => {
    try {
      const response = await apiClient.get(
        `/api/Cargo/GetHavaleHesapList?ilkTarih=${ilkTarih}&SonTarih=${sonTarih}`
      );

      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetHavaleList = async (ilkTarih, sonTarih) => {
    try {
      const response = await apiClient.get(
        `/api/Cargo/GetHavaleList?ilkTarih=${ilkTarih}&SonTarih=${sonTarih}`
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const GetTalimatList = async (ilkTarih, sonTarih) => {
    try {
      const response = await apiClient.get(
        `/api/Cargo/GetTalimatList?ilkTarih=${ilkTarih}&SonTarih=${sonTarih}`
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const GetHavaleBakiye = async () => {
    try {
      const response = await apiClient.get('/api/Cargo/GetHavaleBakiye');
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  return { GetHavaleList, GetHavaleHesapList, GetTalimatList, GetHavaleBakiye };
}
