import { useTranslation } from 'react-i18next';
import {  useState } from 'react';
import { DataGrid } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import '../../../../assets/css/devexpress/dx.common.css';
import '../../../../assets/css/devexpress/dx.material.blue.light.css';
import Layout from '../../../Layouts';
import useMessage from '../../../../features/hooks/useMessage';

function AracUrunListesi() {
  const { t } = useTranslation();
  const [aracNo, setAracNo] = useState('');

  const [data, setData] = useState('');
  const [kriterTipi, setKriterTipi] = useState(0);

  const GetAracUrunListesi = async (_aracNo, _tipi) => {
    const { messageBox } = useMessage()
    if (_aracNo) {
      const data = await GetAracUrunListesi(_aracNo, _tipi);
      if (data) {
        setData(data);
      } else {
        messageBox('kayıt bulunamadı', 'error');
      }
    } else {
      messageBox('kod yazınız..', 'error');
    }
  };

  const colorClass = {
    0: { name: t('B'), class: 'btn-secondary' },
    1: { name: t('G'), class: 'btn-danger' },
    2: { name: t('C'), class: 'btn-success' }
  };

  const renderGirisCikis = (data, field) => {
    const btnClass = colorClass[data.data[field]].class;
    const btnName = colorClass[data.data[field]].name;
    return (
      <button
        className={`btn rounded-pill text-white btn-sm py-1 w-75 gl-btn-max-width ${btnClass}`}>
        {btnName}
      </button>
    );
  };

  return (
    <Layout>
      <div className="order-wrapper m-0">
        <div className="input-group floating-label-group position-relative costumer-z-5">
          <input
            type="text"
            id="aracNo"
            value={aracNo}
            className="form-control py-0"
            required
            onChange={(e) => setAracNo(e.target.value)}
          />
          <label className="floating-label">{t('AracNo')}</label>
          <button
            className="btn btn-outline-primary"
            type="button"
            onClick={() => GetAracUrunListesi(aracNo, kriterTipi)}
            id="button-addon1">
            {t('Search')}
          </button>
        </div>
        <div className="input-group floating-label-group col">
          <select
            className="form-select form-select-lg"
            onChange={(e) => setKriterTipi(e.target.value)}
            name="kriterTipi"
            required
            value={kriterTipi}>
            <option value={0} key={0}>
              Özet
            </option>
            <option value={1} key={1}>
              Ürün Bazında
            </option>
            <option value={2} key={2}>
              Malzeme Bazında
            </option>
          </select>
          <label className="floating-label">{t('Doviz')}</label>
        </div>
      </div>
      <ScrollView direction="horizontal">
        <DataGrid
          dataSource={data}
          allowColumnReordering={false}
          scrollingEnabled={false}
          columnAutoWidth={true}
          focusedRowEnabled={true}
          keyExpr="kayitNo">
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={true} />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Column caption={t('Arac')} dataField={'aracNo'} width={100} />
          <Column
            caption={t('Güm')}
            dataField={'gumrukGirisCikis'}
            width={80}
            cellRender={(data) => renderGirisCikis(data, 'gumrukGirisCikis')}
          />
          <Column
            caption={t('Saha')}
            dataField={'sahaGirisCikis'}
            width={80}
            cellRender={(data) => renderGirisCikis(data, 'sahaGirisCikis')}
          />
          <Column
            caption={t('Kapi')}
            dataField={'kapiGirisCikis'}
            width={80}
            cellRender={(data) => renderGirisCikis(data, 'kapiGirisCikis')}
          />
          <Column caption={t('SoforAdi')} dataField={'soforAdi'} width={150} />
          <Column
            caption={t('Telefon')}
            dataField={'soforTelefonYurtIci'}
            width={130}
          />
          <Column caption={t('Plaka')} dataField={'plaka'} width={110} />
          <Column caption={t('TakipNo')} dataField={'takipNo'} width={100} />
          <Column
            caption={t('Tarih')}
            dataField={'tarih'}
            dataType={'date'}
            format={'dd.MM.yyyy'}
            width={115}
          />
          <Summary>
            <TotalItem column="takipNo" summaryType="count" />
          </Summary>

          <Pager
            allowedPageSizes={[25, 50, 100, 1000]}
            showPageSizeSelector={true}
          />
          <Paging defaultPageSize={50} />
        </DataGrid>
      </ScrollView>
    </Layout>
  );
}
export default AracUrunListesi;
