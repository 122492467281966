import { useEffect, useState } from 'react';
import useCargoApi from '../../../services/api/useCargoApi';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Scrolling,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import { HorizontalLine } from 'devextreme-react/chart';
import WaitingSpinner from '../../../components/WaitingSpinner';
import StatusTracking from '../../../components/StatusTracking';
import { useTranslation } from 'react-i18next';

export default function KargoTakipDetail({ data }) {
  const [detailData, setDetailData] = useState([]);
  const { GetCargoGonderiDetayList } = useCargoApi();
  const rowData = data.row.data;
  const { t } = useTranslation();
  useEffect(() => {
    async function getData() {
      const result = await GetCargoGonderiDetayList(rowData.fisNo);
      setDetailData(result);
    }
    getData();
    return () => {
      setDetailData('');
    };
  }, [rowData]);

  if (detailData) {
    return (
      <>
        <StatusTracking data={rowData.status} />
        <DataGrid
          id="kargodetail"
          dataSource={detailData}
          allowColumnReordering={true}
          // defaultSelectedRowKeys={0}
          columnAutoWidth={true}
          focusedRowEnabled={true}
          keyExpr="kayitNo"
          // onContentReady={(e) => onContentReady(e)}
        >
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={false} />
          <FilterRow visible={false} />
          {/* <Selection mode={'multiple'} /> */}
          <HeaderFilter visible={false} />
          <Scrolling preloadEnabled={true} mode={HorizontalLine} />

          <Column caption={t('StokAdi')} dataField={'stokAdi'} width={95} />

          <Column
            caption={t('M3')}
            dataField={'m3'}
            format="#,##0.00"
            width={110}
          />
          <Column
            caption={t('Kg')}
            dataField={'kg'}
            format="#,##0.00"
            width={110}
          />
          <Column
            caption={t('KapSayisi')}
            dataField={'kapSayisi'}
            width={100}
          />
          <Column
            caption={t('AracNo')}
            dataField={'aracNo'}
            dataType={'string'}
            width={125}
          />
          <Column
            dataField={'kayitNo'}
            caption={t('KayitNo')}
            allowSorting={false}
            allowFiltering={true}
            allowGrouping={false}
            allowReordering={false}
            width={0}
          />
          <Summary>
            <TotalItem column="stokAdi" summaryType="count" />
            <TotalItem column="m3" summaryType="sum" format="#,##0.00" />
            <TotalItem column="kg" summaryType="sum" format="#,##0.00" />
            <TotalItem column="kapSayisi" summaryType="sum" format="#,##0.00" />
          </Summary>
        </DataGrid>
      </>
    );
  } else {
    return <WaitingSpinner data={detailData} />;
  }
}
