import { useState, useEffect } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import apiClient from '@/utils/axios';

const UsePhoneInput = ({ value, onChange }) => {
  const [defaultCountry, setDefaultCountry] = useState('tr'); // Varsayılan ülke kodu

  // Avrupa ülkeleri + Türkiye, Irak ve Çin
  const allowedCountries = [
    'de',
    'fr',
    'es',
    'it',
    'nl',
    'be',
    'pt',
    'se',
    'no',
    'dk',
    'fi',
    'is',
    'tr',
    'iq',
    'cn'
  ];

  useEffect(() => {
    // Kullanıcının IP'sine göre varsayılan ülkeyi belirleme
    const fetchUserCountry = async () => {
      try {
        const response = await apiClient.get('https://ipapi.co/json/');
        const countryCode = response.data.country_code.toLowerCase();
        setDefaultCountry(countryCode); // Varsayılan ülkeyi güncelliyoruz
      } catch (error) {
        console.error('Ülke kodu alınırken hata:', error);
      }
    };

    fetchUserCountry();
  }, []);

  return (
    <div className="phone-input-container">
      {' '}
      {/* Genişliği otomatik olarak ayarlıyoruz */}
      <PhoneInput
        placeholder="Enter phone number"
        value={value}
        onChange={onChange}
        country={defaultCountry} // Varsayılan ülke
        onlyCountries={allowedCountries} // Ülke listesini buradan alıyoruz
        enableSearch={true} // Arama özelliği
        preferredCountries={['tr', 'iq', 'de']} // Tercih edilen ülkeler
        autoFormat={true} // Otomatik formatlama
        disableCountryCodeChange={true} // Ülke kodu değiştirilemez
        countryCodeEditable={false} // Ülke kodu düzenlenemez
      />
    </div>
  );
};

export default UsePhoneInput;
