import { Ring } from '@uiball/loaders';
import './waiting.css';

function WaitingSpinner({ data = null, message = 'Loading' }) {
  if (!data) {
    return (
      // Tam ekran, ortalanmış ve arka plan renkli spinner kapsayıcısı
      <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-75 z-50">
        <Ring size={64} lineWeight={5} speed={2} color="#36d7b7" />
      </div>
    );
  }

  return <h3 className="text-center mt-4 text-blue-600">{message}</h3>;
}

export default WaitingSpinner;
