const trJSON = {
  Aciklama: 'Açıklama',
  Add: 'Ekle',
  Added: 'Başarıyla Eklendi.',
  Address: 'Adres',
  AdiSoyadi: 'Adı Soyadı',
  Adres: 'Adres',
  Agustos: 'Ağustos',
  Alacak: 'Alacak',
  Alias: 'Görünen Ad',
  AliciAdi: 'Alıcı Adı',
  AliciKodu: 'Alıcı Kodu',
  AppMenu: 'Uygulama',
  Arabic: 'Arapça',
  AracNo: 'Araç No',
  AracTakip: 'Araç Takip',
  AracTakipGumruk: 'Araç Takip Gümrük',
  AracTakipYurtDisi: 'Araç Takip',
  Aralik: 'Aralık',
  AraToplam: 'Ara Toplam',
  AuthorizationsDenied:
    'Yetkiniz olmayan bir alana girmeye çalıştığınız tespit edildi.',
  Bakiye: 'Bakiye',
  AltBakiye: 'ALT BAKİYE',
  Bekleme: 'Bekleme',
  Bildirimler: 'Bildirimler',
  BirHataOlustu: 'Bir hata oluştu',
  Borc: 'Borç',
  Bosaltma: 'Boşlatma',
  BuAy: '1 Ay',
  Bugun: 'Bugün',
  BuHafta: 'Bu Hafta',
  BuYil: '1 Yıl',
  ChangePassword: 'Parola Değiştir',
  Cikis: 'Çıkış',
  CitizenNumber: 'Vatandaşlık No',
  Code: 'Kod',
  ConfirmPassword: 'Parola Doğrula',
  CouldNotBeVerifyCid: 'Kimlik No Doğrulanamadı.',
  Create: 'Yeni',
  Degistir: 'Değiştir',
  Delete: 'Sil',
  DeleteConfirm: 'Emin misiniz?',
  Deleted: 'Başarıyla Silindi.',
  Depo: 'Depo',
  Description: 'Açıklama',
  Dil: 'Dil',
  Doctors: 'Doktor',
  Doviz: 'Döviz',
  Durum: 'Durum',
  Ekim: 'Ekim',
  Email: 'E-posta',
  English: 'ingilizce',
  EpostaAdresi: 'E-Posta Adresi',
  EvrakNo: 'Evrak No',
  Eylul: 'Eylül',
  FaturaBilgileri: 'Fatura Bilgileri',
  FirmaAdi: 'Firm Adı',
  FullName: 'Tam Adı',
  GeciciSifre: 'Geçici Şifre',
  Geri: 'Geri',
  German: 'Almanca',
  Giris: 'Giriş',
  GirisEkraninaYonlendiriliyor: 'Giriş Ekranına Yönlendiriliyor',
  GirisYap: 'Giriş Yap',
  GonderenAdi: 'Gönderen Adı',
  GonderilenHavale: 'Gönderilen Havale',
  GroupList: 'Grup Listesi',
  GroupName: 'Grup Adı',
  Groups: 'Gruplar',
  GrupPermissions: 'Grup Yetkileri',
  Gsm: 'Cep Telefonu',
  Gumruk: 'Gümrük',
  GumrukCikis: 'Gümrük Çıkış',
  Hakkimizda: 'Hakkımızda',
  Hakkinda: 'Hakkında',
  Havale: 'Havale',
  HavaleHesap: 'Havale Hesap',
  HavaleListesi: 'Havale Listesi',
  Haziran: 'Haziran',
  InvalidCode: 'Geçersiz Bir Kod Girdiniz!',
  id: 'KayitNo',
  iletisim: 'İletişim',
  iptal: 'iptal',
  isim: 'İsim',
  Kampanya: 'Kampanya',
  KapSayisi: 'Kap Sayısı',
  Kargo: 'Gönderi',
  Hesap: 'Hesap',
  HesapEkstresi: 'Hesap Ekstresi',
  UrunListesi: 'Ürün Listesi',
  UrunTakip: 'Ürün Takip',
  Kasim: 'Kasım',
  Kaydet: 'Kaydet',
  KayitNo: 'KayıtNo',
  KayitOl: 'Kayıt Ol',
  Kg: 'Kg',
  konum: 'Konum',
  KullaniciAdi: 'Kullanıcı Adı',
  Kurdish: 'Kürtçe',
  LangCode: 'Dil Kodu',
  LanguageList: 'Dil Listesi',
  Languages: 'Diller',
  Login: 'Giriş',
  LogList: 'İşlem Kütüğü',
  M3: 'M3',
  M3Kg: 'M3 / Kg',
  mail: 'E-Mail',
  MalKabul: 'Mal Kabul',
  Management: 'Yönetim',
  Mart: 'MArt',
  Mayis: 'Mayıs',
  Menu: 'Menü',
  MevcutSifre: 'Mevcut Şifre',
  MobilePhones: 'Cep Telefonu',
  Name: 'Adı',
  NameAlreadyExist: 'Oluşturmaya Çalıştığınız Nesne Zaten Var.',
  Nisan: 'Nisan',
  NoRecordsFound: 'Kayıt Bulunamadı',
  Notes: 'Notlar',
  Ocak: 'Ocak',
  Ok: 'Tamam',
  Okundu: 'Okundu',
  OkunmamisMesajlar: 'Okunmamış Mesajlar',
  Onaylandı: 'Onaylandı',
  OneriSikikayet: 'Öneri Şikayet',
  OperationClaim: 'Operasyon Yetkileri',
  OperationClaimExists: 'Bu operasyon izni zaten mevcut.',
  OperationClaimList: 'Yetki Listesi',
  Ödendi: 'Ödendi',
  Password: 'Parola',
  PasswordDigit: 'En Az 1 Rakam İçermelidir!',
  PasswordEmpty: 'Parola boş olamaz!',
  PasswordError:
    'Kimlik Bilgileri Doğrulanamadı, Kullanıcı adı ve/veya parola hatalı.',
  PasswordLength: 'Minimum 8 Karakter Uzunluğunda Olmalıdır!',
  PasswordLowercaseLetter: 'En Az 1 Küçük Harf İçermelidir!',
  PasswordSpecialCharacter: 'En Az 1 Simge İçermelidir!',
  PasswordUppercaseLetter: 'En Az 1 Büyük Harf İçermelidir!',
  Permissions: 'Permissions',
  phone1: 'Telefon-1',
  phone2: 'Telefon-2',
  phone3: 'Telefon-3',
  Plaka: 'Plaka',
  Profil: 'Profil',
  Rapor: 'Rapor',
  Required: 'Bu alan zorunludur!',
  Saha: 'Saha',
  Save: 'Kaydet',
  Sehir: 'Şehir',
  SendMobileCode: 'Lütfen Size SMS Olarak Gönderilen Kodu Girin!',
  SendPassword: 'Yeni Parolanız E-Posta Adresinize Gönderildi.',
  Sifirla: 'Sıfırla',
  Sifre: 'Şifre',
  SifreDegistir: 'Şifre Değiştir',
  SifreEslesmiyor: 'Şifre eşleşmiyor',
  SifremiUnuttum: 'Şifremi Unuttum',
  SifremVar: 'Şifrem Var',
  SifrenizDahaOnceGonderildi: 'Şifreniz daha önce gönderilmiştir',
  SifreTelefonaGonderildi: 'Şifreniz telefona gönderildi',
  SifreYenile: 'Şİfre Yenile',
  SifreyiDogrula: 'Şifreyi Doğrula',
  Sira: 'Sıra',
  SmsServiceNotFound: 'SMS Servisine Ulaşılamıyor.',
  SoforAdi: 'Şoför Adı',
  StokAdi: 'Stok Adı',
  StringLengthMustBeGreaterThanThree:
    'Lütfen En Az 3 Karakterden Oluşan Bir İfade Girin.',
  Subat: 'Şubat',
  SubeListesi: 'Şube Listesi',
  SuccessfulLogin: 'Sisteme giriş başarılı.',
  TakipNo: 'Takip No',
  TalimatListesi: 'Talimat Listesi',
  Tarih: 'Tarih',
  TarihTutar: 'Tarih - Tutar',
  Telefon: 'Telefon',
  Telefon1: 'Telefon-1',
  Telefon2: 'Telefon-2',
  TelefonVeyaEmail: 'Telefon veya Email',
  Temmuz: 'Temmuz',
  Teslim: 'Taslim',
  Tipi: 'Tipi',
  title: 'Başlık',
  TokenProviderException: 'Token Provider boş olamaz!',
  TranslateList: 'Dil Çeviri Listesi',
  TranslateWords: 'Dil Çevirileri',
  TrueButCellPhone: 'Bilgiler doğru. Cep telefonu gerekiyor.',
  TumunuGoruntule: 'Tümünü Görüntüle',
  Turkish: 'Türkçe',
  Tutar: 'Tutar',
  Ulke: 'Ülke',
  Unknown: 'Bilinmiyor!',
  Unvan: 'Ünvan',
  Update: 'Güncelle',
  Updated: 'Başarıyla Güncellendi.',
  UserList: 'Kullanıcı Listesi',
  UserNotFound:
    'Kimlik Bilgileri Doğrulanamadı. Lütfen Yeni Kayıt Ekranını kullanın.',
  Users: 'Kullanıcılar',
  UsersClaims: 'Kullanıcının Yetkileri',
  UsersGroups: 'Kullanıcının Grupları',
  UyeOl: 'Üye ol',
  Value: 'Değer',
  VergiDairesi: 'Vergi dairesi',
  VergiNumarası: 'Vergi Numarası',
  VerifyCid: 'Kimlik No Doğrulandı.',
  WrongCitizenId:
    'Vatandaşlık No Sistemimizde Bulunamadı. Lütfen Yeni Kayıt Oluşturun!',
  YeniSifre: 'Yeni Şifre',
  Yukleme: 'Yükleme',
  ZatenKayitli: 'Zaten Kayıtlı',
  HesabiSil: 'Hesabı Sil',
  TakipEt: 'TakipEt',
  DepoBakiye: 'Depo Bakiye',
  Detay: 'Detay',
  PaketSayisi: 'Paket Sayısı',
  HavaleBakiye: 'Havale Bakiye',
  NakitOdeme: 'Nakit Ödeme',
  NakliyeOdeme: 'Göderi Ödeme',
  KomisyonOdeme: 'Komisyon Ödeme',
  KasapHesap: 'Kasap Hesap',
  EvHesap: 'Ev Hesap',
  BankaDinarOdeme: 'Banka Dinar Ödeme',
  MusteriKodu: 'Müşteri Kodu',
  UserCode: 'Kullanıcı Kodu',
  AcountDelete: 'Hesabı Sil',
  Location: 'Konum',
  Yes: 'Evet',
  No: 'Hayır',
  Warning: 'Uyarı',
  LocationUpdateSuccess: 'Konum baş başarıyla güncellendi',
  RecordLocationInformation: 'Konum bilgisi kayıt edilsin mi?',
  DoYouWantToDeleteTheAccount: 'Hesabı silmek istiyor musunuz?',
  GetAddressWithLocation: 'Konum ile adres al',
  LocationInformation: 'Konum bilgisi',
  LocationInformationRecorded: 'Konum bilgisi kayıt edildi',
  LocationInformationNotRecorded: 'Konum bilgisi kayıt edilmedi',
  LocationSaveInformation: 'Konum bilgisi kayıt edilsin mi?',
  Dashboard: 'Anasayfa',
  OrderList: 'Sipariş Listesi',
  WarehouseBalance: 'Depo Bakiye',
  OfficeBalance: 'Ofis Bakiye',
  MainAcount: 'Ana Hesap',
  SubAcount: 'Alt Hesap',
  SendCode: 'Kodu Gönder',
  Confirm: 'Doğrula',
  Lutfen6HaneliDogrulamaKodunuGiriniz:
    'Lütfen 6 haneli doğrulama kodunu giriniz.',
  LutfenSizeGonderilenDogrulamaKodunuGirin:
    'Lütfen size gönderilen doğrulama kodunu girin.'
};

export default trJSON;
