// src/components/ProfileMenu.js
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './ChangeLanguage';
import useAuth from '@/features/hooks/useAuth';
import useMenu from '@/features/hooks/useMenu';
import { renderIcon } from '@/utils/iconRenderer';

export default function ProfileMenu({ scaleClass, setIsShowProfile }) {
  const { logout, user } = useAuth();
  const { selectedComponentHandler } = useMenu();
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.nav-user-container')) {
        setIsShowProfile(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [setIsShowProfile]);

  const showLayout = scaleClass ? "show-layout" : "";

  return (
    <>
      <div className={`gray-layout ${showLayout}`} />
      <div id="list-example" className={`shadow rounded position-absolute bg-white nav-user-container pb-2 list-group ${scaleClass}`}>
        <div className='rounded p-3 gl-page-header-container-secondary align-items-center mb-2'>
          <img
            src={`${user?.image}?v=${Math.random()}`} 
            width={32}
            height={32}
            className="rounded-circle"
            alt="User Avatar"
            onError={(e) => { e.target.src = "/images/avatar/UserNull.png"; }}
          />
          <p className='text-center fs-6 my-0 ms-2'>{user?.fullName}</p>
        </div>

      
        <ul className="list-group list-group-flush">
          <li className="list-group-item bg-light">
            <NavLink to={"/profile"} className='px-4 py-2 text-center d-flex align-items-center justify-content-between text-hover black-color mx-2 rounded-pill'
            onClick={() => { selectedComponentHandler(""); setIsShowProfile(false); }}>
            <div className='d-flex align-items-center gap-2'>
            {renderIcon('user',24)}
            {t("Profil")}
            </div>
            </NavLink>
          </li>
          <li className="list-group-item bg-light">
          <ChangeLanguage />
          </li>
          {/* <li className="list-group-item bg-light">
          <ThemeSwitcher />
          </li> */}
       
        <li className="list-group-item bg-light flex justify-center"  onClick={logout}>
        {renderIcon('power-off',24 ,'text-danger')}
        <span className=' m-2'>
         {t("Cikis")}</span>
        </li>
        </ul>
        </div>
    </>
  );
}
