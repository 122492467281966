import Layout from '../../Layouts';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useDate from '../../../features/hooks/useDate';
import ScrollView from 'devextreme-react/scroll-view';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';
import '../../../assets/css/devexpress/dx.common.css';
import '../../../assets/css/devexpress/dx.material.blue.light.css';
import useHavaleApi from '../../../services/api/useHavaleApi';
import HavaleHesapDetail from './HavaleHesapDetail';
import { useParams } from 'react-router-dom';
import WaitingSpinner from '@/components/WaitingSpinner';

function HavaleHesap() {
  const { t } = useTranslation();
  const { GetHavaleHesapList } = useHavaleApi();
  const { Kodu } = useParams();
  const [data, setData] = useState('');
  const { ilkTarih, sonTarih } = useDate();

  useEffect(() => {
    async function getData() {
      const result = await GetHavaleHesapList(ilkTarih, sonTarih, Kodu);
      setData(result);
    }

    getData();

    return () => {
      setData('');
    };
  }, [ilkTarih, sonTarih]);

  if (data) {
    return (
      <Layout>
        <ScrollView direction="horizontal">
          <DataGrid
            scrollByContent={true}
            scrollByThumb={true}
            dataSource={data}
            allowColumnReordering={false}
            scrollingEnabled={false}
            // defaultSelectedRowKeys={0}
            columnAutoWidth={true}
            focusedRowEnabled={true}
            keyExpr="kayitNo"
            // onContentReady={(e) => contentReady(e)}
          >
            <GroupPanel visible={false} />
            <Grouping autoExpandAll={true} />
            <FilterRow visible={true} />
            {/* <Selection mode={'multiple'} /> */}
            <HeaderFilter visible={true} />
            <Column caption={t('Tipi')} dataField={'tip'} width={75} />
            <Column
              caption={t('Aciklama')}
              dataField={'aciklama'}
              width={150}
            />

            <Column
              caption={t('Borc')}
              dataField={'borcOrj'}
              format="#,###,##0.00"
              width={100}
            />
            <Column
              caption={t('Alacak')}
              dataField={'alcakOrj'}
              format="#,###,##0.00"
              width={100}
            />
            <Column
              caption={t('Bakiye')}
              dataField={'bakiyeOrj'}
              format="#,###,##0.00"
              width={100}
            />
            <Column
              caption={t('Doviz')}
              dataField={'doviz'}
              dataType={'string'}
              width={75}
            />
            <Column
              caption={t('Tarih')}
              dataField={'tarih'}
              dataType={'date'}
              format={'dd.MM.yyyy'}
              width={85}
            />

            <Column
              dataField={'kayitNo'}
              caption={t('KayitNo')}
              allowSorting={false}
              allowFiltering={true}
              allowGrouping={false}
              allowReordering={false}
              width={100}
              visible={false}
            />
            <Summary>
              <TotalItem column="tarih" summaryType="count" />
              {/* <TotalItem
                column="borcOrj"
                summaryType="sum"
                valueFormat="#,###,##0.00"
              />
              <TotalItem
                column="alcakOrj"
                summaryType="sum"
                valueFormat="#,###,##0.00"
              /> */}
            </Summary>
            <MasterDetail
              enabled={true}
              render={(e) => <HavaleHesapDetail data={e} />}
            />

            <Pager
              allowedPageSizes={[25, 50, 100, 1000]}
              showPageSizeSelector={true}
            />
            <Paging defaultPageSize={50} />
          </DataGrid>
        </ScrollView>
      </Layout>
    );
  } else {
    return <WaitingSpinner data={data} />;
  }
}
export default HavaleHesap;
