//useCargoApi.jsx
import useMessage from '@/features/hooks/useMessage';
import apiClient from '@/utils/axios';
export default function useCargoApi() {
  const { messageBox } = useMessage();

  const GetCargoGonderiDetayList = async (EvrakNo) => {
    try {
      const response = await apiClient.get(
        '/api/Cargo/GetCargoGonderiDetayList?EvrakNo=' + EvrakNo
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const GetCargoTakipList = async (subAccount = 0) => {
    try {
      const response = await apiClient.get('/api/Cargo/GetCargoTakipList', {
        params: {
          subAccount: subAccount
        }
      });
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetCargoGonderiList = async (ilkTarih, sonTarih, subAccount = 0) => {
    try {
      const response = await apiClient.get(
        '/api/Cargo/GetCargoGonderiList?ilkTarih=' +
          ilkTarih +
          '&SonTarih=' +
          sonTarih +
          '&subAccount=' +
          subAccount
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const GetCargoHesapList = async (ilkTarih, sonTarih) => {
    try {
      const response = await apiClient.get(
        '/api/Cargo/GetCargoHesapList?ilkTarih=' +
          ilkTarih +
          '&SonTarih=' +
          sonTarih
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetCargoBakiye = async (subAccount = 0) => {
    try {
      const response = await apiClient.get('/api/Cargo/GetCargoBakiye', {
        params: {
          subAccount: subAccount
        }
      });
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const GetAnaCariBakiyeList = async (subAccount = 0) => {
    try {
      const response = await apiClient.get('/api/Cargo/GetAnaCariBakiyeList', {
        params: {
          subAccount: subAccount
        }
      });
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };

  const GetAracTakipGumrukAracList = async () => {
    try {
      const response = await apiClient.get(
        '/api/cargo/GetAracTakipGumrukAracList'
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetAracTakipYurtIciOzetAracList = async () => {
    try {
      const response = await apiClient.get(
        '/api/cargo/GetAracTakipYurtIciOzetAracList'
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetAracTakipYurtIciAracList = async () => {
    try {
      const response = await apiClient.get(
        '/api/cargo/GetAracTakipYurtIciAracList'
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetAracTakipYurtDisiAracList = async () => {
    try {
      const response = await apiClient.get(
        '/api/cargo/GetAracTakipYurtDisiAracList'
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetAracTakipYurtIciFullList = async (Takipci) => {
    try {
      const response = await apiClient.get(
        `/api/cargo/GetAracTakipYurtIciFullList?Takipci=${Takipci}`
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const SetAracTakipGirisCikis = async (AracNo, Islem, Operasyon) => {
    try {
      const response = await apiClient.get(
        `/api/cargo/SetAracTakipGirisCikis?AracNo=${AracNo}&Islem=${Islem}&Operasyon=${Operasyon}`
      );
      const data = response.data.data[0];

      if (data.sonuc) {
        messageBox(data.message, 'success');
      } else {
        messageBox(data.message, 'error');
      }

      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  const GetAracUrunListesi = async (AracNo, Tipi) => {
    try {
      const response = await apiClient.get(
        `/api/cargo/GetAracUrunListesi?AracNo=${AracNo}&Tipi=${Tipi}`
      );
      const data = response.data.data[0];

      if (data.sonuc) {
        messageBox(data.message, 'success');
      } else {
        messageBox(data.message, 'error');
      }

      return data;
    } catch (err) {
      messageBox(err, 'error');
    }
  };
  // const GetCargoTakipList = async () => {
  //   try {
  //     const response = await apiClient.get('/api/Cargo/GetCargoTakipList');
  //     const data = response.data.data;
  //     return data;
  //   } catch (err) {
  //     return err;
  //   }
  // };
  // const GetCargoGonderiList = async (ilkTarih, sonTarih) => {
  //   try {
  //     const response = await apiClient.get(
  //       '/api/Cargo/GetCargoGonderiList?ilkTarih=' +
  //         ilkTarih +
  //         '&SonTarih=' +
  //         sonTarih
  //     );
  //     const data = response.data.data;
  //     return data;
  //   } catch (err) {
  //     return err;
  //   }
  // };
  // const GetCargoGonderiDetayList = async (EvrakNo) => {
  //   try {
  //     const response = await apiClient.get(
  //       '/api/Cargo/GetCargoGonderiDetayList?EvrakNo=' + EvrakNo
  //     );
  //     const data = response.data.data;
  //     return data;
  //   } catch (err) {
  //     return err;
  //   }
  // };
  // const GetCargoHesapList = async (ilkTarih, sonTarih) => {
  //   try {
  //     const response = await apiClient.get(
  //       '/api/Cargo/GetCargoHesapList?ilkTarih=' +
  //         ilkTarih +
  //         '&SonTarih=' +
  //         sonTarih
  //     );
  //     const data = response.data.data;
  //     return data;
  //   } catch (err) {
  //     return err;
  //   }
  // };
  const GetHavaleHesapList = async (ilkTarih, sonTarih) => {
    try {
      const response = await apiClient.get(
        '/api/Cargo/GetHavaleHesapList?ilkTarih=' +
          ilkTarih +
          '&SonTarih=' +
          sonTarih
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      return err;
    }
  };
  const GetHavaleList = async (ilkTarih, sonTarih) => {
    try {
      const response = await apiClient.get(
        '/api/Cargo/GetHavaleList?ilkTarih=' +
          ilkTarih +
          '&SonTarih=' +
          sonTarih
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      return err;
    }
  };
  const GetTalimatList = async (ilkTarih, sonTarih) => {
    try {
      const response = await apiClient.get(
        '/api/Cargo/GetTalimatList?ilkTarih=' +
          ilkTarih +
          '&SonTarih=' +
          sonTarih
      );
      const data = response.data.data;
      return data;
    } catch (err) {
      return err;
    }
  };
  const GetNotificationList = async () => {
    try {
      const response = await apiClient.get('/api/Cargo/GetNotificationList');
      const data = response.data.data;
      return data;
    } catch (err) {
      return err;
    }
  };
  const SetNotification = async () => {
    try {
      const response = await apiClient.get('/api/Cargo/SetNotification');
      const data = response.data.data;
      return data;
    } catch (err) {
      return err;
    }
  };
  return {
    GetCargoBakiye,
    GetAracTakipGumrukAracList,
    GetAracTakipYurtIciAracList,
    GetAracTakipYurtDisiAracList,
    SetAracTakipGirisCikis,
    GetAracTakipYurtIciOzetAracList,
    GetAracUrunListesi,
    GetAracTakipYurtIciFullList,
    GetAnaCariBakiyeList,
    GetCargoTakipList,
    GetCargoGonderiList,
    GetCargoHesapList,
    GetHavaleHesapList,
    GetHavaleList,
    GetTalimatList,
    GetNotificationList,
    GetCargoGonderiDetayList,
    SetNotification
  };
}
