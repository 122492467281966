import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useMenu from '@/features/hooks/useMenu';
import useAuth from '@/features/hooks/useAuth';
import { getRoleBasedMenuItems } from '@/utils/menuItems';
import './bottommenu.css';
import { renderIcon } from '@/utils/iconRenderer';

export default function BottomMenu() {
  const { t } = useTranslation();
  const { selectedComponentHandler } = useMenu();
  const { user } = useAuth();
  const [menuItems, setMenuItems] = useState([]);

  // Bottom Menu için geçerli olan menü öğelerini filtreleyin ve sıralayın
  useEffect(() => {
    if (user) {
      const { authenticationProviderType } = user;
      const items = getRoleBasedMenuItems(authenticationProviderType).map((item) => ({
        ...item,
        name: t(item.name),
      }));
      const bottomMenuItems = items
        .filter((item) => item.isBottomMenu)
        .sort((a, b) => a.isBottomMenuOrder - b.isBottomMenuOrder);

      setMenuItems(bottomMenuItems);
    }
  }, [user, t]);

  return (
    <nav className="navbar fixed-bottom navbar-light bg-white bg-light m-0 shadow-sm d-md-none py-2 bottom-menu">
    <div className="container-fluid d-flex justify-content-around p-0 bottom-menu-container">
      {menuItems.slice(0, 5).map((item) => (
        <NavLink
          key={item.id}
          to={item.to}
          className={`d-flex flex-column align-items-center col menu-button ${item.isCenterIcon ? 'center-icon' : ''}`}
          onClick={() => selectedComponentHandler(item.id)}
        >
          <div className="icon">{renderIcon(item.icon)}</div>
          <span className="bottom-text-secondary">{t(item.name)}</span>
        </NavLink>
      ))}
    </div>
  </nav>
  );
}
