import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAppApi from '@/services/api/setUserApi';
import UsePhoneInput from '@/components/Use/UsePhoneInput/UsePhoneInput';
import { renderIcon } from '@/utils/iconRenderer';
import { t } from 'i18next';

export default function ForgetPassword() {
  const [userName, setUserName] = useState('');
  const [phone, setPhone] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Uyarı mesajı için state
  const { ForgotPassword } = useAppApi();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userName || !phone) {
      setErrorMessage('Lütfen kullanıcı adı ve telefon numarasını giriniz.'); // Hata mesajı güncelleniyor
      return;
    }

    setErrorMessage(''); // Başarılı giriş denemesinde hata mesajını temizle

    const response = await ForgotPassword({
      UserName: userName,
      MobilePhone: phone
    });

    if (response.success) {
      navigate('/auth/verify', { state: { userName } });
    }
  };

  return (
    <div className="container px-4 px-lg-0 login-container">
      <div className="row d-grid d-flex bg-sm-image">
        <div className="m-auto rounded-lg form-login g-0 ">
          <div className="card g-0" border="gray">
            <div className="card-header card-header-title text-color-success">
              {renderIcon('password', 32, 'text-color-black')}
              <div>{t('SifremiUnuttum')}</div>
            </div>

            <div className="card-body">
              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Uyarı Mesajı */}
                {errorMessage && (
                  <div className="alert alert-danger text-center mb-4">
                    {errorMessage}
                  </div>
                )}

                {/* Kullanıcı Adı */}
                <div className="input-group my-2">
                  <div className="bg-color-layout-shadow d-flex align-items-center p-1 px-2">
                    <span className="black-color">
                      {renderIcon('user', 20, 'text-color-black')}
                    </span>
                  </div>
                  <input
                    className="formControl-text form-control"
                    id="username"
                    type="text"
                    placeholder={t('UserCode')}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>

                {/* Telefon Numarası */}
                <div className="relative form-outline">
                  <UsePhoneInput
                    value={phone}
                    onChange={(value) => setPhone(value)}
                    className="form-control w-full px-4 py-2 border border-gray-300 rounded focus:ring-indigo-200 focus:border-indigo-500"
                    placeholder=" "
                  />
                  <label htmlFor="phone" className="form-label"></label>
                </div>

                <div className="flex justify-center items-center content-center">
                  <button className="btn-login mx-3" type="submit">
                    {renderIcon('send-to-mobile', 32, 'text-color-black')}
                    <p className="my-1 mx-2">{t('SendCode')}</p>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
