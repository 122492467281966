import { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { renderIcon } from '@/utils/iconRenderer';
import { t } from 'i18next';
import useAppApi from '@/services/api/setUserApi';
import useMessage from '@/features/hooks/useMessage';
import './ResetPassword.css';

export default function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const passwordInputRef = useRef(null);
  const { state } = useLocation();
  const userName = state?.userName || '';
  const otp = state?.otp || '';
  const { ResetPassword } = useAppApi();
  const navigate = useNavigate();
  const { messageBox } = useMessage();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newPassword) {
      setError(t('Lütfen şifre girin')); // Set error message
      passwordInputRef.current.focus(); // Focus on the input
      return;
    }

    const response = await ResetPassword({
      UserName: userName,
      Code: otp,
      NewPassword: newPassword
    });

    if (response) {
      messageBox(response.message, 'success');
      navigate('/auth/login');
    }
  };

  return (
    <div className="container px-4 px-lg-0 login-container">
      <div className="row d-grid d-flex bg-sm-image">
        <div className="m-auto rounded-lg form-login g-0">
          <div className="card g-0" border="gray">
            <div className="card-header card-header-title color-menu-title">
              {renderIcon('power-reset', 32, 'text-color-black')}
              <div>{t('SifreYenile')}</div>
            </div>

            <div className="card-body">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="relative form-outline">
                  <input
                    type="password"
                    id="newPassword"
                    ref={passwordInputRef}
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setError(''); // Clear error on change
                    }}
                    className={`form-control w-full px-4 py-2 border rounded focus:ring-indigo-200 focus:border-indigo-500 mt-2 mb-3 ${
                      error ? 'border-red-500' : 'border-gray-300'
                    }`}
                    placeholder=" "
                    required
                  />
                  <label htmlFor="newPassword" className="form-label">
                    {t('YeniSifre')}
                  </label>
                  {error && <span className="text-red-500">{error}</span>}{' '}
                  {/* Show error message */}
                </div>

                <button
                  type="submit"
                  className="btn-reset w-full flex items-center justify-center px-5 py-2 color-text bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-indigo-300 transition duration-150 ease-in-out mt-7 mb-4 mx-2 my-2">
                  {renderIcon('domain-verification', 24, ' mx-2')}
                  {t('SifreYenile')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
