import { NavLink } from 'react-router-dom';
import './menupage.css';
import useMenu from '@/features/hooks/useMenu';
import Layout from '../Layouts';
import Dashboard from '@/routes/dashboard';
import { renderIcon } from '@/utils/iconRenderer';
import { useTranslation } from 'react-i18next';

function MenuPage() {
  const { currentMenus, selectedComponentHandler, CurrentComponent } =
    useMenu();
  const { t } = useTranslation();
  const navClickHandler = (e) => {
    const selectedMenuId = e.currentTarget.value;
    selectedComponentHandler(selectedMenuId);
  };

  const menuItems = currentMenus.filter((item) => item.isShowMenu);
  const hasWindow = typeof window !== 'undefined';
  const width = hasWindow ? window.innerWidth : null;

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row gx-4">
          {menuItems.map((menu) => (
            <NavLink
              to={menu.to}
              key={menu.name}
              value={menu.id}
              className="p-1 col-4 col-lg-2 btn text-white gl-fs-6"
              onClick={navClickHandler}>
              <div className="mainpage-grid-container main-menu-button">
                {renderIcon(menu.icon, 32)}
                <p className="mainpage-text">
                  {menu.name.length > 0 &&
                  menu.name.indexOf(' ') === -1 &&
                  width < 600
                    ? `${t(menu.name).substr(0, 12)}..`
                    : t(menu.name)}
                </p>
              </div>
            </NavLink>
          ))}
        </div>
        <div className="mt-4">
          {CurrentComponent ? <CurrentComponent /> : <Dashboard />}
        </div>
      </div>
    </Layout>
  );
}

export default MenuPage;
