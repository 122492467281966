import WaitingSpinner from '@/components/WaitingSpinner';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function HavaleHesapDetail({ data }) {
  const { t } = useTranslation();
  const rowData = data.row.data;
  const { adi, belgeNo, evrakTipi, aciklama, tip, cinsi, projeKodu } = rowData;

  if (rowData) {
    return (
      <>
        <div className="bg-white">
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td width={50}>{t('BelgeNo')}</td>
                <td width={5}>:</td>
                <td>{belgeNo}</td>
              </tr>
              <tr>
                <td width={50}>{t('EvrakTipi')}</td>
                <td width={5}>:</td>
                <td>{evrakTipi}</td>
              </tr>
              <tr>
                <td width={50}>{t('Adi')}</td>
                <td width={5}>:</td>
                <td>{adi}</td>
              </tr>
              <tr>
                <td width={50}>{t('Aciklama')}</td>
                <td width={5}>:</td>
                <td>{aciklama}</td>
              </tr>
              <tr>
                <td width={50}>{t('ProjeKodu')}</td>
                <td width={5}>:</td>
                <td>{projeKodu}</td>
              </tr>
              <tr>
                <td width={50}>{t('Cinsi')}</td>
                <td width={5}>:</td>
                <td>{cinsi}</td>
              </tr>
              <tr>
                <td width={50}>{t('Tip')}</td>
                <td width={5}>:</td>
                <td>{tip}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </>
    );
  } else {
    return <WaitingSpinner data={data} />;
  }
}
