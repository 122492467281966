import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function KargoHesapDetail({ data }) {
  const rowData = data.row.data;
  const {
    depo,
    teslimNoktasi,
    kapSayisi,
    kg,
    m3,
    nakliye,
    gumruk,
    komisyon,
    diger,
    iskonto,
    tahsilat
  } = rowData;
  const { t } = useTranslation();

  return (
    <>
      <div className="bg-white">
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td width={50}>{t('Nakliye')}</td>
              <td width={5}>:</td>
              <td>{nakliye}</td>
            </tr>
            <tr>
              <td width={50}>{t('Gumruk')}</td>
              <td width={5}>:</td>
              <td>{gumruk}</td>
            </tr>
            <tr>
              <td width={50}>{t('Komisyon')}</td>
              <td width={5}>:</td>
              <td>{komisyon}</td>
            </tr>
            <tr>
              <td width={50}>{t('Diger')}</td>
              <td width={5}>:</td>
              <td>{diger}</td>
            </tr>
            <tr>
              <td width={50}>{t('iskonto')}</td>
              <td width={5}>:</td>
              <td>{iskonto}</td>
            </tr>
            <tr>
              <td width={50}>{t('Tahsilat')}</td>
              <td width={5}>:</td>
              <td>{tahsilat}</td>
            </tr>
            <tr>
              <td width={50}>{t('Depo')}</td>
              <td width={5}>:</td>
              <td>{depo}</td>
            </tr>
            <tr>
              <td width={50}>{t('TeslimNoktasi')}</td>
              <td width={5}>:</td>
              <td>{teslimNoktasi}</td>
            </tr>
            <tr>
              <td width={50}>{t('KapSayisi')}</td>
              <td width={5}>:</td>
              <td>{kapSayisi}</td>
            </tr>
            <tr>
              <td width={50}>{t('M3')}</td>
              <td width={5}>:</td>
              <td>{m3}</td>
            </tr>
            <tr>
              <td width={50}>{t('Kg')}</td>
              <td width={5}>:</td>
              <td>{kg}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}
