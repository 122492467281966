import { useTranslation } from 'react-i18next';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { useEffect, useState } from 'react';
import useMenu from '@/features/hooks/useMenu';
import useCargoApi from '@/services/api/useCargoApi';
import CountUp from 'react-countup';
import Layout from '@/content/Layouts';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: 'Tarih - M3'
    }
  }
};

function AracTakipDashboard() {
  const { t } = useTranslation();
  const { GetAracTakipYurtIciOzetAracList } = useCargoApi();
  const [data, setData] = useState('');
  const { findSelectedComponentByUsingId } = useMenu();

  useEffect(() => {
    async function getData() {
      const result = await GetAracTakipYurtIciOzetAracList();
      setData(result[0]);
    }
    if (!data) {
      getData();
    }
    return () => {
      setData('');
    };
  }, []);

  let bekleyen = data.bekleyen;
  let biten = data.biten;
  let kapi = data.kapi;
  let saha = data.saha;
  let yol = data.yol;

  return (
    <Layout>
      <div className="container-fluid px-0 mb-4">
        <div className="row gx-0 gap-3">
          <div className="bg-white costum-card rounded-3 p-4 text-secondary position-relative col-lg-4 gl-card-decorative">
            <h4 className="text-secondary mb-0">{t('Bekleyen')}</h4>
            <CountUp
              start={0}
              end={bekleyen}
              duration={2}
              decimals={0}
              separator="."
              decimal=",">
              {({ countUpRef }) => (
                <h1
                  ref={countUpRef}
                  className="mt-2 text-color-decorative"></h1>
              )}
            </CountUp>
            <button
              className="btn btn-lg bg-color-decorative text-white"
              onClick={() => findSelectedComponentByUsingId(2)}>
              {t('Detay')}
            </button>
            <div className="position-absolute top-0 end-0">
              <img src="/images/icons/madalya.svg" />
            </div>
          </div>
          <div className="bg-white rounded-3 p-4 d-flex align-items-center justify-content-between text-secondary col-lg-4 gl-card-decorative">
            <div className="d-flex flex-column align-items-center">
              <div className="bg-color-decorative text-white rounded-circle p-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div>
                <p className="text-secondary mb-0 text-center mt-1">
                  {t('Yol')}
                </p>
                <CountUp
                  start={0}
                  end={yol}
                  duration={2}
                  decimals={0}
                  decimal=",">
                  {({ countUpRef }) => (
                    <h3
                      ref={countUpRef}
                      className="mt-1 text-color-decorative text-center"></h3>
                  )}
                </CountUp>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="bg-color-decorative text-white rounded-circle p-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <div>
                <p className="text-secondary mb-0 text-center mt-1">
                  {t('Saha')}
                </p>
                <CountUp
                  start={0}
                  end={saha}
                  duration={2}
                  decimals={0}
                  decimal=",">
                  {({ countUpRef }) => (
                    <h3
                      ref={countUpRef}
                      className="mt-1 text-color-decorative text-center"></h3>
                  )}
                </CountUp>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="bg-color-decorative text-white rounded-circle p-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
                  />
                </svg>
              </div>
              <div>
                <p className="text-secondary mb-0 text-center mt-1">
                  {t('Kapi')}
                </p>
                <CountUp
                  start={0}
                  end={kapi}
                  duration={2}
                  decimals={0}
                  decimal=",">
                  {({ countUpRef }) => (
                    <h3
                      ref={countUpRef}
                      className="mt-1 text-color-decorative text-center"></h3>
                  )}
                </CountUp>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-3 p-4 d-flex align-items-center justify-content-between text-secondary col-lg-4 gl-card-decorative">
            <div className="d-flex flex-column align-items-center">
              <div className="bg-color-decorative text-white rounded-circle p-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div>
                <p className="text-secondary mb-0 text-center mt-1">
                  {t('Biten')}
                </p>
                <CountUp
                  start={0}
                  end={biten}
                  duration={2}
                  decimals={0}
                  decimal=",">
                  {({ countUpRef }) => (
                    <h3
                      ref={countUpRef}
                      className="mt-1 text-color-decorative text-center"></h3>
                  )}
                </CountUp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default AracTakipDashboard;
