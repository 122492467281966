import { useState, createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getRoleBasedMenuItems } from '@/utils/menuItems';
import useAuth from '@/features/hooks/useAuth';

export const MenuContext = createContext({});

export const MenuProvider = ({ children }) => {
  const { t } = useTranslation();
  const { userRole, isAuthenticated } = useAuth();
  const [currentMenus, setCurrentMenus] = useState([]);
  const [selectedComponentId, setSelectedComponentId] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      // Kullanıcının rolüne göre menü öğelerini ayarlar
      const menus = getRoleBasedMenuItems(userRole).map((menu) => ({
        ...menu,
        name: t(menu.name) // Menü adlarını çevirir
      }));
      setCurrentMenus(menus);
    }
  }, [userRole, isAuthenticated, t]);

  const selectedComponentHandler = (id) => setSelectedComponentId(id);

  const findSelectedComponentByUsingId = (id) => {
    const menu = currentMenus.find((menu) => menu.id === id);
    if (menu) {
      setSelectedComponentId(menu.id);
    }
  };

  // Geçerli menü öğesine karşılık gelen bileşeni seçer
  const CurrentComponent =
    selectedComponentId !== null
      ? currentMenus.find((menu) => menu.id === selectedComponentId)?.component
      : null;

  return (
    <MenuContext.Provider
      value={{
        currentMenus,
        selectedComponentId,
        CurrentComponent,
        selectedComponentHandler,
        findSelectedComponentByUsingId
      }}>
      {children}
    </MenuContext.Provider>
  );
};
