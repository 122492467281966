import { pageConfigurations } from './pageConfigurations';

export const getRoleBasedMenuItems = (role) => {
  const baseMenuItems = {
    Customer: [
      {
        key: 'DashboardCustomer',
        isShowMenu: true,
        isSidebarMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 1
      },
      {
        key: 'UrunTakip',
        isShowMenu: true,
        isSidebarMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 2
      },
      {
        key: 'UrunListesi',
        isShowMenu: true,
        isSidebarMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 4
      },
      {
        key: 'HesapEkstresi',
        isShowMenu: true,
        isSidebarMenu: true,
        isBottomMenu: false,
        isBottomMenuOrder: 0,
        isCenterIcon: false
      },
      {
        key: 'HavaleHesap',
        isShowMenu: true,
        isSidebarMenu: true,
        isBottomMenu: true,
        isCenterIcon: false,
        isBottomMenuOrder: 5
      },
      { key: 'HavaleListesi', isShowMenu: true, isSidebarMenu: true },
      { key: 'TalimatListesi', isShowMenu: true, isSidebarMenu: true },
      { key: 'SubeListesi', isShowMenu: true, isSidebarMenu: true },
      {
        key: 'Kampanya',
        isShowMenu: false,
        isBottomMenu: false,
        isBottomMenuOrder: 0
      },
      { key: 'FaturaBilgileri', isShowMenu: false, isSidebarMenu: true },
      {
        key: 'Hakkinda',
        isShowMenu: true,
        isSidebarMenu: true,
        isBottomMenu: false,
        isBottomMenuOrder: 5,
        isCenterIcon: false
      },
      {
        key: 'MenuPage',
        isShowMenu: false,
        isSidebarMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 3,
        isCenterIcon: true
      }
    ],
    Admin: [
      { key: 'UrunTakip', isShowMenu: true },
      { key: 'UrunListesi', isShowMenu: true },
      { key: 'HesapEkstresi', isShowMenu: true },
      { key: 'Hakkinda', isShowMenu: true }
    ],
    Follower1: [
      {
        key: 'DashboardCustomer',
        isShowMenu: true,
        isSidebarMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 1
      },
      {
        key: 'MenuPage',
        isShowMenu: false,
        isSidebarMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 3,
        isCenterIcon: true
      },
      {
        key: 'AracTakipGumruk',
        isShowMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 2,
        isSidebarMenu: true
      },
      {
        key: 'SubeListesi',
        isShowMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 4,
        isSidebarMenu: true
      },
      { key: 'Kampanya', isShowMenu: true, isSidebarMenu: true },
      {
        key: 'FaturaBilgileri',
        isShowMenu: false,
        isBottomMenu: true,
        isBottomMenuOrder: 5,
        isSidebarMenu: true
      },
      { key: 'Hakkinda', isShowMenu: false }
    ],
    Follower2: [
      {
        key: 'DashboardCustomer',
        isShowMenu: true,
        isSidebarMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 1
      },
      {
        key: 'MenuPage',
        isShowMenu: false,
        isSidebarMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 3,
        isCenterIcon: true
      },
      {
        key: 'AracTakip',
        isShowMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 2,
        isSidebarMenu: true
      },
      {
        key: 'SubeListesi',
        isShowMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 4,
        isSidebarMenu: true
      },
      { key: 'Kampanya', isShowMenu: true, isSidebarMenu: true },
      {
        key: 'FaturaBilgileri',
        isShowMenu: false,
        isBottomMenu: true,
        isBottomMenuOrder: 5,
        isSidebarMenu: true
      },
      { key: 'Hakkinda', isShowMenu: false }
    ],
    Follower3: [
      {
        key: 'DashboardCustomer',
        isShowMenu: true,
        isSidebarMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 1
      },
      {
        key: 'MenuPage',
        isShowMenu: false,
        isSidebarMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 3,
        isCenterIcon: true
      },
      {
        key: 'AracTakipYurtDisi',
        isShowMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 2,
        isSidebarMenu: true
      },
      {
        key: 'SubeListesi',
        isShowMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 4,
        isSidebarMenu: true
      },
      { key: 'Kampanya', isShowMenu: true, isSidebarMenu: true },
      {
        key: 'FaturaBilgileri',
        isShowMenu: false,
        isBottomMenu: true,
        isBottomMenuOrder: 5,
        isSidebarMenu: true
      },
      { key: 'Hakkinda', isShowMenu: false }
    ],
    FinanceManager: [
      {
        key: 'DashboardCustomer',
        isShowMenu: true,
        isSidebarMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 1
      },
      {
        key: 'MenuPage',
        isShowMenu: false,
        isSidebarMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 3,
        isCenterIcon: true
      },
      {
        key: 'CariHesapLimit',
        isShowMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 2,
        isSidebarMenu: true
      },
      {
        key: 'SubeListesi',
        isShowMenu: true,
        isBottomMenu: true,
        isBottomMenuOrder: 4,
        isSidebarMenu: true
      },
      { key: 'Kampanya', isShowMenu: true, isSidebarMenu: true },
      {
        key: 'FaturaBilgileri',
        isShowMenu: false,
        isBottomMenu: true,
        isBottomMenuOrder: 5,
        isSidebarMenu: true
      },
      { key: 'Hakkinda', isShowMenu: false }
    ]
  };

  return (
    baseMenuItems[role]?.map((item, index) => ({
      ...item,
      ...pageConfigurations[item.key], // Sayfa konfigürasyonlarından veriyi çek
      id: index
    })) || []
  );
};
