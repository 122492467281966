export const branch = [
    {
        "id": "1",
        "title": "Merkez Ofis",
        "phone1": "+90 342 231 17 20",
        "phone2": "+90 342 231 17 21",
        "phone3": "+90 342 230 13 76",
        "mail": "info@altuntur.com",
        "address": "AYDINLAR MAH. 03063 NOLU CAD. NO:25/1 ŞEHİTKAMİL",
        "ulke": "Türkiye",
        "sehir": "Gaziantep",
        "konum": ""
    },


    {
        "id": "2",
        "title": "Gaziantep Depo",
        "phone1": "+90 342 241 51 91",
        "phone2": "+90 342 241 51 01",
        "phone3": "",
        "mail": "info@altuntur.com",
        "address": "AYDINLAR MAH. 03063 NOLU CAD. NO:25/1 ŞEHİTKAMİL",
        "ulke": "TÜRKİYE",
        "sehir": "Gaziantep",
        "konum": ""
    },


    {
        "id": "3",
        "title": "Beyazıt Ofis",
        "phone1": "+90 212 516 29 15",
        "phone2": "+90 212 518 37 07",
        "phone3": "+90 212 516 28 46",
        "mail": "info@altuntur.com",
        "address": "ORDU CADDESİ ÖZBEK ÇARŞISI NO: 13/32 BEYAZIT",
        "ulke": "TÜRKİYE",
        "sehir": "istanbul",
        "konum": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3010.7352899017164!2d28.963144302368164!3d41.0091667175293!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab98fc73cfe3f%3A0x5c912cea9df24cc9!2sALTUNTUR%20NAKL%C4%B0YE%20BEYAZIT%20OF%C4%B0S!5e0!3m2!1str!2str!4v1645253760292!5m2!1str!2str"
    },


    {
        "id": "4",
        "title": "Haseki Depo",
        "phone1": "+90 212 633 63 66",
        "phone2": "+90 212 530 84 30",
        "phone3": "",
        "mail": "info@altuntur.com",
        "address": "HASEKİ SULTAN MAH. CERRAH PAŞA CAD. NO 44 HASEKİ",
        "ulke": "TÜRKİYE",
        "sehir": "istanbul",
        "konum": ""
    },


    {
        "id": "5",
        "title": "Merter Ofis",
        "phone1": "+90 212 642 45 27",
        "phone2": "+90 212 677 37 97",
        "phone3": "+90 212 677 37 96",
        "mail": "info@altuntur.com",
        "address": "Mehmet Nesih Özmen Mh. Yüksek Sk. No:2 Palmiye İş Merkezi 1/14",
        "ulke": "TÜRKİYE",
        "sehir": "istanbul",
        "konum": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2531.5714261197554!2d28.883774191941804!3d41.01294256580284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x341732e8b7b1ef34!2zNDHCsDAwJzQ4LjQiTiAyOMKwNTMnMDEuMCJF!5e0!3m2!1str!2str!4v1645253820414!5m2!1str!2str"
    },


    {
        "id": "6",
        "title": "Merter Depo",
        "phone1": "+90 212 642 45 27",
        "phone2": "+90 212 677 37 97",
        "phone3": "+90 212 677 37 96",
        "mail": "info@altuntur.com",
        "address": "MEHMET NESİH ÖZMEN MAH. DAVAŞ CAD. NO:26 AKASYA SK. BAŞI",
        "ulke": "TÜRKİYE",
        "sehir": "istanbul",
        "konum": ""
    },


    {
        "id": "7",
        "title": "Yenibosna Depo",
        "phone1": "+90 212 470 70 45",
        "phone2": "+90 212 633 63 67",
        "phone3": "+90 212 530 84 30",
        "mail": "info@altuntur.com",
        "address": "Yenibosna merkez mahallesi cemal ulusoy caddesi ibrahim çaça sokak no:12 Yenibosna / Bahçelievler",
        "ulke": "TÜRKİYE",
        "sehir": "istanbul",
        "konum": ""
    },


    {
        "id": "8",
        "title": "Bursa Ofis",
        "phone1": "+90 530 956 55 17",
        "phone2": "",
        "phone3": "",
        "mail": "bursaofis@altuntur.com",
        "address": "KAZIM KARABEKİR MAH. KARAPINAR SK. NO:8 YILDIRIM/BURSA (TEVEKKÜL KANTAR CİVARI)",
        "ulke": "TÜRKİYE",
        "sehir": "Bursa",
        "konum": ""
    },


    {
        "id": "9",
        "title": "Bursa Depo",
        "phone1": "+90 530 100 50 73",
        "phone2": "+90 224 215 01 56",
        "phone3": "+90 224 215 01 57",
        "mail": "bursadepo@altuntur.com",
        "address": "KAZIM KARABEKİR MAH. KARAPINAR SK. NO:8 YILDIRIM/BURSA (TEVEKKÜL KANTAR CİVARI)",
        "ulke": "TÜRKİYE",
        "sehir": "Bursa",
        "konum": ""
    },


    {
        "id": "10",
        "title": "Erbil Ofis 1",
        "phone1": "+964 750 453 37 49",
        "phone2": "",
        "phone3": "",
        "mail": "erbilofis1@altunntur.com",
        "address": "ERBIL-VANA MALL",
        "ulke": "IRAK",
        "sehir": "Erbil",
        "konum": ""
    },


    {
        "id": "11",
        "title": "Necef Ofis",
        "phone1": "+964 780 146 33 33",
        "phone2": "",
        "phone3": "",
        "mail": "necefofis@altuntur.com",
        "address": "",
        "ulke": "IRAK",
        "sehir": "Necef",
        "konum": ""
    },


    {
        "id": "12",
        "title": "Erbil Ofis 2",
        "phone1": "+964 750 357 33 73",
        "phone2": "",
        "phone3": "",
        "mail": "erbilofis2@altunntur.com",
        "address": "",
        "ulke": "IRAK",
        "sehir": "Erbil",
        "konum": ""
    },


    {
        "id": "13",
        "title": "Erbil Depo",
        "phone1": "+964 750 453 37 50",
        "phone2": "",
        "phone3": "",
        "mail": "erbildepo@altuntur.com",
        "address": "ERBIL-REGAYI GUER PİŞT HAZAYI MURUR",
        "ulke": "IRAK",
        "sehir": "Erbil",
        "konum": ""
    },


    {
        "id": "14",
        "title": "Erbil Europe Depo",
        "phone1": "+964 750 315 03 33",
        "phone2": "",
        "phone3": "",
        "mail": "erbilEuropoDepo@altuntur.com",
        "address": "ERBIL-30 M NIZIK GAZINO ANTER",
        "ulke": "IRAK",
        "sehir": "Erbil",
        "konum": ""
    },

    {
        "id": "15",
        "title": "Süleymaniye Ofis",
        "phone1": "+964 770 770 30 78",
        "phone2": "",
        "phone3": "",
        "mail": "suleymaniyeofis@altuntur.com",
        "address": "SÜLIYIMANIYA- TANİŞT BAZARI DOLAR BAZARI REBAT K1",
        "ulke": "IRAK",
        "sehir": "Süleymaniye",
        "konum": ""
    },


    {
        "id": "16",
        "title": "Süleymaniye Depo",
        "phone1": "+964 750 405 03 33",
        "phone2": "",
        "phone3": "",
        "mail": "suleymaniyedepo@altuntur.com",
        "address": "SÜLIYIMANIYA-MANTIKA SINAYA PİŞT NAKILYAT BAĞDAT KIN FAHSI MODRIN",
        "ulke": "IRAK",
        "sehir": "Süleymaniye",
        "konum": ""
    },


    {
        "id": "17",
        "title": "Duhok Ofis",
        "phone1": "+964 750 450 50 51",
        "phone2": "",
        "phone3": "",
        "mail": "duhokofis@altuntur.com",
        "address": "DAHUK-KUÇ SENTAR K3",
        "ulke": "IRAK",
        "sehir": "Duhok",
        "konum": ""
    },


    {
        "id": "18",
        "title": "Duhok Depo",
        "phone1": "+964 750 850 50 51",
        "phone2": "",
        "phone3": "",
        "mail": "duhokdepo@altuntur.com",
        "address": "DAHUK-CADA BAHAR SITI GÜMRGA HISHEK",
        "ulke": "IRAK",
        "sehir": "Duhok",
        "konum": ""
    },


    {
        "id": "19",
        "title": "Duhok Express-1 / Express-2",
        "phone1": "+964 750 480 01 22",
        "phone2": "",
        "phone3": "",
        "mail": "info@altuntur.com",
        "address": "DAHUK-CADA BAHAR SITI GÜMRGA HISHEK",
        "ulke": "IRAK",
        "sehir": "Duhok",
        "konum": ""
    },


    {
        "id": "20",
        "title": "Kerkük Ofis 1",
        "phone1": "+964 770 126 67 84",
        "phone2": "",
        "phone3": "",
        "mail": "",
        "address": "KERKÜK-SARE MUHAFZA MUKABIL MAKSI MALL",
        "ulke": "IRAK",
        "sehir": "Kerkük",
        "konum": ""
    },


    {
        "id": "21",
        "title": "Kerkük Ofis 2",
        "phone1": "+964 771 750 99 95",
        "phone2": "",
        "phone3": "",
        "mail": "kerkukofis@altuntur.com",
        "address": "KERKÜK-SÜK KEBIR KAYSERIYA SUK ZAHEB",
        "ulke": "IRAK",
        "sehir": "Kerkük",
        "konum": ""
    },


    {
        "id": "22",
        "title": "Kerkük Depo",
        "phone1": "+964 750 931 03 33",
        "phone2": "",
        "phone3": "",
        "mail": "kerkukdepo@altuntur.com",
        "address": "KERKÜK- TARIK SÜLIYMANIYA MERKEZ ALTAKAFIYA KERKÜK",
        "ulke": "IRAK",
        "sehir": "Kerkük",
        "konum": ""
    },


    {
        "id": "23",
        "title": "Zaho Ofis",
        "phone1": "+964 750 442 80 66",
        "phone2": "",
        "phone3": "",
        "mail": "zahoofis@altuntur.com",
        "address": "CADE DELAL ZAHO",
        "ulke": "IRAK",
        "sehir": "Zaho",
        "konum": ""
    },


    {
        "id": "24",
        "title": "Zaho Depo",
        "phone1": "+964 750 480 84 99",
        "phone2": "",
        "phone3": "",
        "mail": "zahodepo@altuntur.com",
        "address": "CADE DELAL ZAHO",
        "ulke": "IRAK",
        "sehir": "Zaho",
        "konum": ""
    },

    {
        "id": "25",
        "title": "Diyana Depo - Ofis",
        "phone1": "+964 750 936 03 33",
        "phone2": "",
        "phone3": "",
        "mail": "",
        "address": "DİYANA. ASWAQ ZAGROS BARAMBAR AHMADI HANI",
        "ulke": "IRAK",
        "sehir": "Diyana",
        "konum": ""
    },


    {
        "id": "26",
        "title": "Bağdat Ofis-1",
        "phone1": "+964 770 118 74 51",
        "phone2": "",
        "phone3": "",
        "mail": "bagdatofis1@altuntur.com",
        "address": "BAĞDAT-ŞARENAHER SUİK ALABAÇI",
        "ulke": "IRAK",
        "sehir": "Bağdat",
        "konum": ""
    },


    {
        "id": "27",
        "title": "Bağdat Ofis-2",
        "phone1": "+964 772 852 77 61",
        "phone2": "",
        "phone3": "",
        "mail": "bagdatofis2@altuntur.com",
        "address": "BAĞDAT-MANSUR TAKATE AL RAWAD",
        "ulke": "IRAK",
        "sehir": "Bağdat",
        "konum": ""

    },


    {
        "id": "28",
        "title": "Bağdat Ofis-3",
        "phone1": "+964 772 852 77 60",
        "phone2": "+964 770 969 44 66",
        "phone3": "",
        "mail": "bagdatofis3@altuntur.com",
        "address": "BAĞDAT- KERADA ŞARE ALATAR MUCAMA ALWAW",
        "ulke": "IRAK",
        "sehir": "Bağdat",
        "konum": ""

    },


    {
        "id": "29",
        "title": "Bağdat Depo-1",
        "phone1": "+964 772 852 77 62",
        "phone2": "+964 780 314 33 33",
        "phone3": "",
        "mail": "info@altuntur.com",
        "address": "BAĞDAT-KANAT",
        "ulke": "IRAK",
        "sehir": "Bağdat",
        "konum": ""

    },


    {
        "id": "30",
        "title": "Bağdat Depo-2",
        "phone1": "+964 772 852 77 63",
        "phone2": "+964 772 852 77 62",
        "phone3": "",
        "mail": "info@altuntur.com",
        "address": "BAĞDAT- ŞARE ALCUMURIYA MKABIL EEDADIYA ALMARKAZIYA",
        "ulke": "IRAK",
        "sehir": "Bağdat",
        "konum": ""

    },


    {
        "id": "31",
        "title": "Kerbela Depo",
        "phone1": "+964 781 339 90 35",
        "phone2": "",
        "phone3": "",
        "mail": "kerbeladepo@altuntur.com",
        "address": "",
        "ulke": "IRAK",
        "sehir": "Kerbela",
        "konum": ""

    },



    {
        "id": "32",
        "title": "Basra Ofis",
        "phone1": "+964 770 956 93 61",
        "phone2": "",
        "phone3": "",
        "mail": "basraofis@altuntur.com",
        "address": "",
        "ulke": "IRAK",
        "sehir": "Basra",
        "konum": ""

    },



    {
        "id": "33",
        "title": "Basra Depo",
        "phone1": "+964 770 956 93 60",
        "phone2": "",
        "phone3": "",
        "mail": "basradepo@altuntur.com",
        "address": "",
        "ulke": "IRAK",
        "sehir": "Basra",
        "konum": ""

    },


    {
        "id": "34",
        "title": "Hille Ofis",
        "phone1": "0750 450 50 512",
        "phone2": "0750 453 37 50",
        "phone3": "0750 405 03 33",
        "phone4": "0750 931 03 33",
        "mail": "hille@altuntur.com",
        "address": "40 cadde Altin Çarşısı Hille",
        "ulke": "IRAK",
        "sehir": "Hille",
        "konum": ""

    }


]




