import { useEffect, useState } from 'react';
import './change-password.css';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../features/hooks/useAuth';
import useMessage from '../../../features/hooks/useMessage';
import { t } from 'i18next';
import { renderIcon } from '@/utils/iconRenderer';

function ChangePassword() {
  const { user, isAuthenticated, SetUserPassword, logout } = useAuth();
  const [password, setPassword] = useState('');
  const [passwordVerification, setPasswordVerification] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [requestPending] = useState(false);
  const { messageBox } = useMessage();

  let navigate = useNavigate();

  const isSubmit = currentPassword && password && passwordVerification;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/auth/login');
    }
  }, [isAuthenticated, navigate]);

  async function submitHandler(e) {
    e.preventDefault();

    if (password !== passwordVerification) {
      messageBox(t('SifreEslesmiyor'), 'error');
      return;
    }

    await SetUserPassword(currentPassword, password);

    navigate('/');
  }

  // Geri butonuna tıklandığında localStorage'ı temizleyen ve login sayfasına yönlendiren fonksiyon
  const handleBack = async () => {
    // Update the authentication state using a proper method
    // Assuming you have a logout function in useAuth hook
    await logout();
  };

  return (
    <div className="container px-4 px-lg-0 mt-4">
      <h3 className="text-color-success text-center mb-0 fw-6">
        {t('SifreDegistir')}
      </h3>
      <div className="row d-grid d-flex bg-sm-image">
        <div className="mt-4 m-auto rounded-lg form-login g-0 col-lg-6 col-md-8 col-sm-10">
          <div className="card g-0" border="gray">
            <div className="card-body">
              <form onSubmit={(e) => submitHandler(e)} className="d-grid">
                <div className="mb-3">
                  <label htmlFor="currentPassword" className="form-label">
                    {t('MevcutSifre')}
                  </label>
                  <input
                    className="formControl-text form-control"
                    id="currentPassword"
                    type="password"
                    placeholder="Current Password"
                    value={currentPassword}
                    onChange={(e) => {
                      setCurrentPassword(e.target.value);
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    {t('YeniSifre')}
                  </label>
                  <input
                    className="formControl-text form-control"
                    id="password"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="passwordVerification" className="form-label">
                    {t('SifreyiDogrula')}
                  </label>
                  <input
                    className="formControl-text form-control"
                    id="passwordVerification"
                    type="password"
                    placeholder="Verify Password"
                    value={passwordVerification}
                    onChange={(e) => {
                      setPasswordVerification(e.target.value);
                    }}
                  />
                </div>

                <button
                  className="btn btn-full  fw-bold fs-7 rounded bg-color-success text-color  border border-solid border-1 border-dark rounded-pill"
                  onClick={(e) => submitHandler(e)}
                  type="submit">
                  {renderIcon('password', 32, 'mx-2 text-color-primary')}
                  {t('Kaydet')}{' '}
                </button>
              </form>

              {/* Geri Butonu */}
              <button
                className="mt-4 btn btn-light text-dark btn-sm border border-solid border-1 border-dark rounded-pill"
                onClick={handleBack}>
                {renderIcon('back', 32, 'mx-3 my-1')}
                {t('Geri')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
