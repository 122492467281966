import { useTranslation } from 'react-i18next';

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LinearScale
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { t } from 'i18next';
import useHavaleApi from '@/services/api/useHavaleApi';
import useDate from '@/features/hooks/useDate';
import useMonthNames from '@/helpers/useMonthNames';
import useMenu from '@/features/hooks/useMenu';
import useMonth from '@/features/hooks/useMonth';
import Layout from '@/content/Layouts';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: t('TarihTutar')
    }
  }
};

function HavaleDashboard() {
  const { t } = useTranslation();
  const { GetHavaleList, GetHavaleBakiye } = useHavaleApi();
  const [data, setData] = useState('');
  const [bakiye, setBakiye] = useState(0);
  const { ilkTarih, sonTarih } = useDate();
  const { monthNames } = useMonthNames();
  const { findSelectedComponentByUsingId } = useMenu();
  const labels = useMonth();

  useEffect(() => {
    async function getData() {
      const result = await GetHavaleList(ilkTarih, sonTarih);
      setData(result);
      const bakiye = await GetHavaleBakiye();

      setBakiye(bakiye[0]);
    }
    getData();
    return () => {
      setData('');
      setBakiye({});
    };
  }, [ilkTarih, sonTarih]);

  const araToplam = [];

  if (data) {
    data.forEach((el) => {
      const elDate = new Date(el.tarih);
      let currentLabel = '';
      if (elDate.getFullYear() === new Date().getFullYear()) {
        currentLabel = monthNames[elDate.getMonth()];
      } else {
        currentLabel = monthNames[elDate.getMonth()] + elDate.getFullYear();
      }
      labels.forEach((label) => {
        if (label == currentLabel) {
          if (typeof araToplam[labels.indexOf(label)] === 'undefined') {
            araToplam[labels.indexOf(label)] = 0;
            araToplam[labels.indexOf(label)] =
              araToplam[labels.indexOf(label)] + parseInt(el.araToplam);
          } else {
            araToplam[labels.indexOf(label)] =
              araToplam[labels.indexOf(label)] + parseInt(el.araToplam);
          }
        }
      });
    });
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: t('AraToplam'),
        data: araToplam,
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      }
    ]
  };

  return (
    <Layout>
      <div className="container-fluid px-0 mb-4">
        <div className="row gx-0 gap-3">
          <div className="bg-white costum-card rounded-3 p-4 text-secondary position-relative col-lg-4 gl-card-decorative">
            <h4 className="text-secondary mb-0">{t('Bakiye')}</h4>
            <CountUp
              start={0}
              end={bakiye?.bakiye?.toFixed(2)}
              duration={2}
              decimals={2}
              separator="."
              decimal=",">
              {({ countUpRef }) => (
                <h3
                  ref={countUpRef}
                  className="mt-2 text-color-decorative"></h3>
              )}
            </CountUp>
            <button
              className="btn btn-lg bg-color-decorative text-white"
              onClick={() => findSelectedComponentByUsingId(3)}>
              {t('Detay')}
            </button>
            <div className="position-absolute top-0 end-0">
              <img src="/images/icons/madalya.svg" />
            </div>
          </div>

          <div className="gl-card-decorative col-lg-4 rounded-3">
            <div className="card-header bg-color-decorative text-white text-center fw-bold">
              {t('GonderilenHavale')}
            </div>
            <div className="card-body">
              {data && <Bar options={options} data={chartData} />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default HavaleDashboard;
