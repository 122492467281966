import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AracUrunListesi } from '../content/Kargo';
const AracTakip = lazy(() => import('../content/Kargo/AracTakip'));
const AracTakipGumruk = lazy(() => import('../content/Kargo/AracTakipGumruk'));
const AracTakipYurtDisi = lazy(() =>
  import('../content/Kargo/AracTakipYurtDisi')
);
const HavaleHesap = lazy(() => import('../content/Kargo/HavaleHesap'));
const HavaleListesi = lazy(() => import('../content/Kargo/HavaleListesi'));
const KargoHesap = lazy(() => import('../content/Kargo/KargoHesap'));
const KargoListesi = lazy(() => import('../content/Kargo/KargoListesi'));
const KargoTakip = lazy(() => import('../content/Kargo/KargoTakip'));
const Talimat = lazy(() => import('../content/Kargo/Talimat'));

const Kargo = () => {
  return (
    <Routes>
      <Route path="/" children={<KargoTakip />}>
        <Route path="AracTakip" element={<AracTakip />} />
        <Route path="AracTakipGumruk" element={<AracTakipGumruk />} />
        <Route path="AracTakipYurtDisi" element={<AracTakipYurtDisi />} />
        <Route path="KargoTakip" element={<KargoTakip />} />
        <Route path="KargoListesi" element={<KargoListesi />} />
        <Route path="KargoHesap" element={<KargoHesap />} />
        <Route path="HavaleHesap/:Kodu" element={<HavaleHesap />} />
        <Route path="HavaleHesap" element={<HavaleHesap />} />
        <Route path="HavaleListesi" element={<HavaleListesi />} />
        <Route path="TalimatListesi" element={<Talimat />} />
        <Route path="AracUrunListesi" element={<AracUrunListesi />} />
      </Route>
    </Routes>
  );
};

export default Kargo;
