import currency from "currency.js";

// format currency with currency.js on turkish style
const formatCurrency = (value) => {
  if (currency) {
    return currency(value, { symbol: '', decimal: ',', separator: '.' }).format();
  }
  return value
}

export default formatCurrency;