// Bütün bileşenleri sayfa konfigürasyonlarının üstünde içe aktarın
import Branch from '@/content/Branch';
import CargoDashboard from '@/content/Dashboard/Kargo';
import { CariHesapLimit } from '@/content/Finans';
import Kampanya from '@/content/Kampanya';
import {
  AracTakip,
  AracTakipGumruk,
  AracTakipYurtDisi,
  KargoTakip,
  KargoListesi,
  KargoHesap,
  HavaleHesap,
  HavaleListesi,
  Talimat
} from '@/content/Kargo';
import MenuPage from '@/content/MenuPage';
import About from '@/content/pages/About';
import Fatura from '@/content/pages/Fatura';

// pageConfigurations tanımını daha sonra yapın
export const pageConfigurations = {
  UrunTakip: {
    name: 'UrunTakip',
    component: KargoTakip,
    icon: 'shipping-fast',
    to: '/kargo/KargoTakip'
  },
  UrunListesi: {
    name: 'OrderList',
    component: KargoListesi,
    icon: 'list',
    to: '/kargo/KargoListesi'
  },
  HesapEkstresi: {
    name: 'HesapEkstresi',
    component: KargoHesap,
    icon: 'briefcase-outline',
    to: '/kargo/KargoHesap'
  },
  HavaleHesap: {
    name: 'HavaleHesap',
    component: HavaleHesap,
    icon: 'logo-usd',
    to: '/kargo/HavaleHesap'
  },
  HavaleListesi: {
    name: 'HavaleListesi',
    component: HavaleListesi,
    icon: 'money-bill-transfer',
    to: '/kargo/HavaleListesi'
  },
  TalimatListesi: {
    name: 'TalimatListesi',
    component: Talimat,
    icon: 'logo-tumblr',
    to: '/kargo/TalimatListesi'
  },
  SubeListesi: {
    name: 'SubeListesi',
    component: Branch,
    icon: 'storefront-outline',
    to: '/branch'
  },
  Kampanya: {
    name: 'Kampanya',
    component: Kampanya,
    icon: 'gift-outline',
    to: '/Kampanya'
  },
  FaturaBilgileri: {
    name: 'FaturaBilgileri',
    component: Fatura,
    icon: 'reader-outline',
    to: '/fatura'
  },
  Hakkinda: {
    name: 'Hakkinda',
    component: About,
    icon: 'information-outline',
    to: '/about'
  },
  AracTakip: {
    name: 'AracTakip',
    component: AracTakip,
    icon: 'car-sport-outline',
    to: '/kargo/AracTakip'
  },
  AracTakipGumruk: {
    name: 'AracTakipGumruk',
    component: AracTakipGumruk,
    icon: 'car-sport-outline',
    to: '/kargo/AracTakipGumruk'
  },
  AracTakipYurtDisi: {
    name: 'AracTakipYurtDisi',
    component: AracTakipYurtDisi,
    icon: 'car-sport-outline',
    to: '/kargo/AracTakipYurtDisi'
  },
  CariHesapLimit: {
    name: 'CariHesapLimit',
    component: CariHesapLimit,
    icon: 'information-outline',
    to: '/finans/CariHesapLimit'
  },
  MenuPage: {
    name: 'MenuPage',
    component: MenuPage,
    icon: 'menu-outline',
    to: '/menu'
  },
  DashboardCustomer: {
    name: 'Dashboard',
    component: CargoDashboard,
    icon: 'layout-dashboard',
    to: '/Dashboard/kargo'
  }
};
